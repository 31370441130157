/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import {
  Form,
} from 'antd';
import Dialog from '_components/Dialog';
import { useUpdate } from '_service/Order';
import { useStateCompose } from './useStateCompose';
import TForm from './Form';

const AddForm = () => {
  const [form] = Form.useForm();
  const {
    selectedRow, isToggleFormModel, toggleFormModelFn,
  } = useStateCompose();
  const onError = () => {
    toggleFormModelFn();
    form.resetFields();
  };
  const OnSuccess = () => {
    toggleFormModelFn();
    form.resetFields();
  };
  const { mutate, isLoading } = useUpdate(OnSuccess, onError);
  const onOk = () => {
    form.submit();
  };

  const onFinish = (values) => {
    const payload = {
      ...values.order,
      id: selectedRow.id,
    };
    mutate(payload);
  };
  return (
    <>
      {isToggleFormModel && (
      <Dialog
        cancelButtonProps={{ disabled: isLoading }}
        title="Payment Status"
        confirmLoading={isLoading}
        visible={isToggleFormModel}
        onOk={onOk}
        okText="Save"
        onCancel={toggleFormModelFn}
        afterClose={() => { form.resetFields(); }}
      >
        <TForm
          form={form}
          onFinish={onFinish}
          isToggleFormModel={isToggleFormModel}
          selectedRow={selectedRow}
          isLoading={isLoading}
        />

      </Dialog>
      )}
    </>
  );
};

export default AddForm;
