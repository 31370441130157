/* eslint-disable no-return-await */
/* eslint-disable no-unused-vars */
import { apiCaller } from '../middleware/api-caller';
import { ENDPOINTS, REQUEST_TYPES } from '../middleware/url';

export const createOrder = async (data) => await apiCaller(
  {
    method: REQUEST_TYPES.POST,
    url: ENDPOINTS.ORDER,
    Auth: true,
    data,
  },
);

export const getOrder = async ({ queryKey }) => {
  try {
    const [_key, params] = queryKey;
    const data = { ...params };
    if (data.refetch) {
      delete data.refetch;
    }
    const response = await apiCaller({
      method: REQUEST_TYPES.GET,
      url: `${ENDPOINTS.ORDER}/search/`,
      Auth: true,
      params: {
        ...data,
      },
    });
    if (!response) {
      throw new Error('Network response was not ok');
    }
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getOrderById = async ({ queryKey }) => {
  try {
    const [_key, params] = queryKey;
    const data = { ...params };
    if (data.refetch) {
      delete data.refetch;
    }
    // if (data.invoiceStatus && data.invoiceStatus !== '0') {
    //   data.invoice_status = data.invoiceStatus;
    // } else {
    //   data.invoice_status = null;
    // }
    const { id } = data;
    const response = await apiCaller({
      method: REQUEST_TYPES.GET,
      url: `${ENDPOINTS.ORDER}/${id}/`,
      Auth: true,
    });
    if (!response) {
      throw new Error('Network response was not ok');
    }
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getOrderByUser = async ({ queryKey }) => {
  try {
    const [_key, params] = queryKey;
    const data = { ...params };
    const response = await apiCaller({
      method: REQUEST_TYPES.GET,
      url: `${ENDPOINTS.ORDER_CUSTOMER}`,
      Auth: true,
      params: {
        ...data,
      },
    });
    if (!response) {
      throw new Error('Network response was not ok');
    }
    return response;
  } catch (error) {
    throw new Error('notfound');
  }
};
export const update = async (data) => {
  const { id } = data;
  const params = data;
  if (data?.id) { delete params.id; }
  return await apiCaller(
    {
      method: REQUEST_TYPES.PATCH,
      url: `${ENDPOINTS.ORDER}/${id}/`,
      Auth: true,
      // isTransformRequestRequired: true,
      data: { ...params },
    },
  );
};

export const getChartJS = async ({ queryKey }) => {
  try {
    const response = await apiCaller({
      method: REQUEST_TYPES.GET,
      url: `${ENDPOINTS.ORDER}/stats`,
      Auth: true,
    });
    if (!response) {
      throw new Error('Network response was not ok');
    }
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
