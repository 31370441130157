/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Input, Select, Row, Col, Button, Form,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { setFilter, isToggleFormModelFn } from '_store/Location';
import { PlusOutlined } from '@ant-design/icons';

const { Search } = Input;

const Filter = () => {
  const dispatch = useDispatch();
  const toggleIsToggleFormModelFn = () => dispatch(isToggleFormModelFn());
  const [form] = Form.useForm();
  const filters = useSelector(
    (state) => state.Location.filters,
  );
  const setFilterFn = (filter) => dispatch(setFilter(filter));
  return (
    <Form
      layout="horizontal"
      onValuesChange={(key, allFields) => {
        if (key?.filters) { setFilterFn({ ...filters, ...{ ...key.filters, page: 0 } }); }
      }}
      form={form}
    >
      <Row style={{ marginBottom: '16px' }}>
        <Col span={16}>
          <Row gutter={[16, 8]}>
            <Col span={8}>
              <Form.Item name={['order', 'title']} label="">
                <Search
                  allowClear
                  placeholder="search by Title"
                  enterButton="Search"
                  onSearch={(value) => {
                    setFilterFn({ ...filters, ...{ title: value, page: 0 } });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col
          span={7}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button type="primary" shape="circle" onClick={toggleIsToggleFormModelFn} icon={<PlusOutlined />} />
        </Col>
      </Row>
    </Form>
  );
};
export default Filter;
