/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Form as FormAnt,
  Input,
  Select,
} from 'antd';
import PropTypes from 'prop-types';
import { DatePicker } from '_components/DateControls';
import { dateFormat, FORMAT, instanceDayjs } from '_utils/date-helper';
import { usePaymentTypeDropDown } from '_service/paymentType';

const { Option } = Select;
const Form = ({
  form, onFinish, selectedRow, isToggleFormModel,
}) => {
  const [fields, setFields] = useState();
  const { data: paymentType = [] } = usePaymentTypeDropDown({ size: 1000 });
  useEffect(() => {
    setFields([
      {
        name: ['Customer', 'phoneNumber'],
        value: selectedRow?.phoneNumber || null,
      },
      {
        name: ['Customer', 'fullName'],
        value: selectedRow?.fullName || null,
      },
      {
        name: ['Customer', 'billingAddress'],
        value: selectedRow?.billingAddress || null,
      },
      {
        name: ['Customer', 'email'],
        value: selectedRow?.email || null,
      },
      {
        name: ['Customer', 'PaymentTypeId'],
        value: selectedRow?.paymentTypeId || null,
      },
      {
        name: ['Customer', 'company'],
        value: selectedRow?.company || null,
      },
      {
        name: ['Customer', 'customerNumber'],
        value: selectedRow?.customerNumber || null,
      },
      {
        name: ['Customer', 'customerType'],
        value: selectedRow?.customerType || 0,
      },
      {
        name: ['Customer', 'notes'],
        value: selectedRow?.notes || null,
      },
      {
        name: ['Customer', 'paymentTerms'],
        value: selectedRow?.paymentTerms || null,
      },
      {
        name: ['Customer', 'dob'],
        value: selectedRow?.dob ? instanceDayjs(selectedRow?.dob) : null,
      },
      {
        name: ['Customer', 'paymentStartDate'],
        value: selectedRow?.paymentStartDate ? instanceDayjs(selectedRow?.paymentStartDate) : null,
      },
      {
        name: ['Customer', 'paymentEndDate'],
        value: selectedRow?.paymentEndDate ? instanceDayjs(selectedRow?.paymentEndDate) : null,
      },
    ]);
  }, [isToggleFormModel]);
  return (
    <FormAnt
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 14,
      }}
      layout="horizontal"
      form={form}
      name="Edit Customer"
      onFinish={onFinish}
      fields={fields}
      spellCheck={false}
    >
      <FormAnt.Item
        name={['Customer', 'fullName']}
        rules={[{ required: true, message: 'Customer Name required!' }]}
        whitespace={false}
        label="Name"
      >
        <Input />
      </FormAnt.Item>
      <FormAnt.Item
        name={['Customer', 'phoneNumber']}
        whitespace={false}
        label="Phone Number"
      >
        <Input />
      </FormAnt.Item>
      <FormAnt.Item
        name={['Customer', 'email']}
        rules={[{ type: 'email', message: 'Not a valid email !' }]}
        whitespace={false}
        label="Email"
      >
        <Input />
      </FormAnt.Item>
      <FormAnt.Item
        name={['Customer', 'dob']}
        label="DOB"
        whitespace={false}
      >
        <DatePicker
          allowClear
          format={FORMAT.YYYYMMDD}
        />
      </FormAnt.Item>
      <FormAnt.Item
        name={['Customer', 'customerNumber']}
        whitespace={false}
        label="Customer Number"
      >
        <Input spellCheck={false} />
      </FormAnt.Item>
      <FormAnt.Item name={['Customer', 'customerType']} label="Customer Type" rules={[{ required: true, message: 'Please select the Customer Type.' }]}>
        <Select
          showSearch
          placeholder="Customer Type"
          optionFilterProp="children"
        >
          <Option value={0}>Individual</Option>
          <Option value={1}>Company</Option>
        </Select>
      </FormAnt.Item>
      <FormAnt.Item
        name={['Customer', 'company']}
        whitespace={false}
        label="Company"
      >
        <Input spellCheck={false} />
      </FormAnt.Item>
      <FormAnt.Item
        name={['Customer', 'notes']}
        whitespace={false}
        label="Notes"
      >
        <Input.TextArea />
      </FormAnt.Item>
      <FormAnt.Item name={['Customer', 'PaymentTypeId']} label="Preferred Payment Type">
        {paymentType.length > 0 && (
        <Select
          showSearch
          placeholder="Select Payment Type"
          options={paymentType}
        />
        )}
      </FormAnt.Item>
      <FormAnt.Item
        name={['Customer', 'paymentStartDate']}
        label="Payment Type Start Date"
        whitespace={false}
      >
        <DatePicker
          allowClear
          format={FORMAT.YYYYMMDD}
        />
      </FormAnt.Item>
      <FormAnt.Item
        name={['Customer', 'paymentEndDate']}
        label="Payment Type End Date"
        whitespace={false}
      >
        <DatePicker
          allowClear
          format={FORMAT.YYYYMMDD}
        />
      </FormAnt.Item>
      <FormAnt.Item
        name={['Customer', 'paymentTerms']}
        whitespace={false}
        label="Payment terms"
      >
        <Input spellCheck={false} />
      </FormAnt.Item>
      <FormAnt.Item
        name={['Customer', 'billingAddress']}
        whitespace={false}
        label="Billing Address"
      >
        <Input.TextArea />
      </FormAnt.Item>
    </FormAnt>
  );
};

Form.propTypes = {
  form: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
  selectedRow: PropTypes.object.isRequired,
};

export default Form;
