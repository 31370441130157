/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect } from 'react';
import {
  Switch, Route, useRouteMatch, useParams,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setFilterOnRoute, reset } from '_store/PartnerInvoice/InvoiceList';
import FormEdit from 'pages/AddOrder/Edit';
import OrderListView from '../OrderListView';
import OrderDetail from '../OrderDetail';
import BreadCrumbN from '../Components/Header';

const InvoiceListWrapper = () => {
  const Params = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setFilterOnRoute(Params));
    return () => {
      dispatch(reset());
    };
  }, []);
  return (
    <OrderListView />
  );
};

const SubRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <BreadCrumbN />
        <InvoiceListWrapper />
      </Route>
      <Route path={`${path}/details/:id`}>
        <BreadCrumbN />
        <OrderDetail />
      </Route>
      <Route path={`${path}/update/:id`}>
        <BreadCrumbN />
        <FormEdit />
      </Route>
    </Switch>
  );
};

export default SubRoutes;
