import { useGetExpires } from '_service/Customer';

export const useTableView = () => {
  const { data, isFetching } = useGetExpires();
  console.log(data, 'data');
  const {
    expiryDetails = [],

  } = data || {};

  // const news = todayBirthday.concat(upcomingBirthday);

  return {
    dataSource: expiryDetails || [],
    isDataSourceFetching: isFetching,
    total: expiryDetails?.results || 0,
  };
};
