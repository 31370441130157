/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useGetOrder } from '_service/Order';
import {
  setFilter,
  isToggleStatusModel,
  reset,
} from '_store/order';

export const usePartnerInvoice = () => {
  const dispatch = useDispatch();
  const { data, isFetching } = useGetOrder();
  useEffect(() => () => { dispatch(reset()); }, []);
  const filters = useSelector(
    (state) => state.Order.filters,
  );
  const selectedRows = useSelector(
    (state) => state.Order.selectedRows,
  );
  const selectedAll = useSelector(
    (state) => state.Order.selectedAll,
  );
  const setFilterFn = (filter) => dispatch(setFilter(filter));
  const isToggleStatusModelFn = (values) => dispatch(isToggleStatusModel(values));
  return {
    selectedRows,
    filters,
    dataSource: data?.response?.data || [],
    isDataSourceFetching: isFetching,
    total: data?.response?.results || 0,
    selectedAll,
    setFilterFn,
    statusCount: data?.statusCount || {},
    totalCustomer: data?.totalCustomer || 0,
    isToggleStatusModelFn,
  };
};
