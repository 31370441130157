/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Form as FormAnt,
  Input,
} from 'antd';
import PropTypes from 'prop-types';

const Form = ({
  form, onFinish,
}) => (
  <FormAnt
    labelCol={{
      span: 8,
    }}
    wrapperCol={{
      span: 14,
    }}
    layout="horizontal"
    form={form}
    name="provider"
    onFinish={onFinish}
    spellCheck={false}
  >
    <FormAnt.Item
      name={['provider', 'name']}
      rules={[{ required: true, message: 'Please input the provider.' }]}
      whitespace={false}
      label="Provider"
    >
      <Input />
    </FormAnt.Item>
    <FormAnt.Item
      name={['provider', 'phoneNumber']}
      whitespace={false}
      label="Phone Number"
    >
      <Input />
    </FormAnt.Item>
    <FormAnt.Item
      name={['provider', 'email']}
      rules={[{ type: 'email', message: 'Not a valid email !' }]}
      whitespace={false}
      label="Email"
    >
      <Input />
    </FormAnt.Item>
  </FormAnt>
);

Form.propTypes = {
  form: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default Form;
