/* eslint-disable no-console */
import React, { useEffect } from 'react';
import {
  Switch, Route, useRouteMatch, useParams,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setFilterOnRoute, reset } from '_store/PartnerInvoice/InvoiceList';
import OrderDetail from 'pages/order/OrderDetail';
import AddOrder from '../Add';
import FormEdit from '../FormEdit';
import BreadCrumbN from '../Header';

const AddOrderWrapper = () => {
  const Params = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setFilterOnRoute(Params));
    return () => {
      dispatch(reset());
    };
  }, []);
  return (
    <AddOrder />
  );
};

const SubRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <AddOrderWrapper />
      </Route>
      <Route path={`${path}/details/:id`}>
        <BreadCrumbN />
        <OrderDetail created />
      </Route>
      <Route path={`${path}/update/:id`}>
        <BreadCrumbN />
        <FormEdit created />
      </Route>
    </Switch>
  );
};

export default SubRoutes;
