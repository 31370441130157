/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Form as FormAnt,
  InputNumber,
  Input,
  Select,
} from 'antd';
import PropTypes from 'prop-types';
import { DatePicker, TimePicker } from '_components/DateControls';
import DebounceSelect from '_components/Search';
import { instanceDayjs, FORMAT } from '_utils/date-helper';
import { Search } from '_api/Provider';
import { useGetTreatmentByUser } from '_service/treatment';
import { useTreatmentTypeDropDown } from '_service/treatmentType';

const { Option } = Select;

const Form = ({
  form, onFinish, data, isToggleFormModel,
}) => {
  const {
    customerId, locationType,
  } = data || {};
  const [fields, setFields] = useState({});
  const { data: postData, isFetching } = useGetTreatmentByUser({ customerId, locationType });
  const { data: treatmentType = [] } = useTreatmentTypeDropDown({ size: 1000 });
  const { orderDetails = {}, treatmentDetails = {} } = postData || {};
  const {
    billedAmountVat, duration, treatmentNumber, location, locationAddress, id, treatmentType: treatmentTypeList,
  } = orderDetails || {};
  useEffect(() => {
    if (postData) {
      // const { orderDetails = {}, treatmentDetails = {} } = postData || {};
      // const {
      //   billedAmountVat, duration, treatmentNumber, location, locationAddress, id, treatmentType: treatmentTypeList,
      // } = orderDetails || {};
      setFields([
        {
          name: ['treatment', 'providerPrice'],
          value: billedAmountVat ? billedAmountVat / treatmentNumber : 0,
        },
        {
          name: ['treatmentTypeList', 'treatmentTypeId'],
          value: treatmentTypeList ? { label: treatmentTypeList?.title, value: treatmentTypeList?.id } : null,
        },
        {
          name: ['treatment', 'address'],
          value: location?.flag === 1 ? locationAddress : location?.title || null,
        },
        {
          name: ['providers', 'providerId'],
          value: treatmentDetails?.providers ? { label: treatmentDetails?.providers?.name, value: treatmentDetails.providers.id } : null,
        },
        {
          name: ['treatment', 'orderId'],
          value: id || null,
        },
        {
          name: ['treatment', 'notes'],
          value: treatmentDetails?.notes || null,
        },
        {
          name: ['treatment', 'treatmentStatus'],
          value: treatmentDetails?.treatmentStatus || null,
        },
        {
          name: ['treatment', 'treatmentDatetime'],
          value: treatmentDetails?.treatmentDatetime
            ? instanceDayjs(treatmentDetails.treatmentDatetime)
            : null,
        },
        {
          name: ['treatment', 'duration'],
          value: duration || null,
        },
      ]);
    }
  }, [postData]);
  return (
    <FormAnt
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 14,
      }}
      layout="horizontal"
      form={form}
      name="Treatment"
      onFinish={onFinish}
      spellCheck={false}
      fields={fields}
    >
      <FormAnt.Item name={['treatment', 'orderId']} noStyle />
      <FormAnt.Item
        name={['treatment', 'treatmentDatetime']}
        label="Date & Time"
        rules={[{ required: true, message: 'Please select date !' }]}
        whitespace={false}
      >
        <DatePicker
          allowClear
          showTime
          format={FORMAT.YYYYMMDDHHMM}
        />
      </FormAnt.Item>
      <FormAnt.Item name={['treatmentTypeList', 'treatmentTypeId']} label="Treatment Type" rules={[{ required: true, message: 'Please select the Treatment Type.' }]}>
        <Select
          showSearch
          placeholder="Select Treatment Type"
          allowClear
          optionFilterProp="children"
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {treatmentType.map((item) => (
            <Option key={item.value}>{item.label}</Option>
          ))}
        </Select>
      </FormAnt.Item>
      <FormAnt.Item
        name={['treatment', 'duration']}
        label="Duration"
        rules={[{ required: true, message: 'Please input the Duration.' },
          () => ({
            validator(_, value) {
              if (value != null && value <= 0) {
                return Promise.reject(new Error('Value must be grater then zero'));
              }
              return Promise.resolve();
            },
          }),
        ]}
        onChange={(e) => {
          setTimeout(() => {
            try {
              if (e.target.value) {
                const p = billedAmountVat / treatmentNumber || 0;
                const newPrice = (p / duration) * e.target.value;
                form.setFieldsValue({
                  treatment: { providerPrice: newPrice.toFixed(2) },
                });
              }
            } catch (v) {
              console.log(v);
            }
          }, 300);
        }}
      >
        <InputNumber addonAfter="hours" />
      </FormAnt.Item>
      <FormAnt.Item name={['treatment', 'treatmentStatus']} label="Status" rules={[{ required: true, message: 'Please select status.' }]}>
        <Select
          showSearch
          placeholder="Select status"
          optionFilterProp="children"
        >
          <Option value={1}>Booked</Option>
          <Option value={2}> Cancelled </Option>
        </Select>
      </FormAnt.Item>
      <FormAnt.Item label="Provider" name={['providers', 'providerId']} rules={[{ required: true, message: 'Please select the Provider.' }]}>
        <DebounceSelect
          showSearch
          allowClear
          placeholder="search provider"
          fetchOptions={Search}
          style={{ width: 200 }}
        />
      </FormAnt.Item>
      <FormAnt.Item
        name={['treatment', 'providerPrice']}
        whitespace={false}
        label="Price"
        rules={[{ required: true, message: 'Please input the price' },
          () => ({
            validator(_, value) {
              if (value != null && value <= -1) {
                return Promise.reject(new Error('invalid value'));
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input type="number" suffix="SEK" />
      </FormAnt.Item>
      <FormAnt.Item
        name={['treatment', 'address']}
        whitespace={false}
        label="Address"
      >
        <Input.TextArea />
      </FormAnt.Item>
      <FormAnt.Item
        name={['treatment', 'notes']}
        whitespace={false}
        label="Notes"
      >
        <Input.TextArea />
      </FormAnt.Item>

    </FormAnt>
  );
};

Form.propTypes = {
  form: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default Form;
