/* eslint-disable no-return-assign */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import {
  Form as FormAnt,
  Select,
  Input,
  InputNumber,
  Typography,
} from 'antd';
import { Search } from '_api/promo-code';
import { usePaymentTypeDropDown } from '_service/paymentType';
import DebounceSelect from '_components/SearchInput';
import PropTypes, { string } from 'prop-types';
import { getDiscountPrice, getPrice, getVat } from 'utilities';
import { dateFormat, FORMAT, instanceDayjs } from '_utils/date-helper';
import { DatePicker } from '_components/DateControls';
import { Search as treatmentFetch } from '_api/treatmentType';
import { Search as locationFetch } from '_api/location';

const { Text } = Typography;

const { Option } = Select;
const Form = ({
  form, onFinish, selectedRow, isToggleFormModel,
}) => {
  const [fields, setFields] = useState();

  const { data: paymentType = [] } = usePaymentTypeDropDown({ size: 1000 });
  useEffect(() => {
    setFields([

      {
        name: ['list', 'startFrom'],
        value: instanceDayjs(selectedRow.startFrom) || '',
      },

      {
        name: ['select', 'paymentTypeId'],
        value: selectedRow?.paymentTypeId || '',
      },
      {
        name: ['select', 'statusId'],
        value: selectedRow?.statusId || 0,
      },
      {
        name: ['select', 'paymentRef'],
        value: selectedRow?.paymentRef || '',
      },
      {
        name: ['select', 'source'],
        value: selectedRow?.source || '',
      },
    ]);
  }, [isToggleFormModel]);
  return (
    <FormAnt
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 14,
      }}
      layout="horizontal"
      form={form}
      name="Status"
      onFinish={onFinish}
      fields={fields}
    >
      <FormAnt.Item name={['select', 'source']} label="Booking Source">
        <Input />
      </FormAnt.Item>
      <FormAnt.Item name={['list', 'startFrom']} label="start Date">
        <DatePicker
          allowClear
          format={FORMAT.YYYYMMMDD}
        />
      </FormAnt.Item>
      <FormAnt.Item name={['select', 'paymentTypeId']} label="Payment Type" rules={[{ required: true, message: 'Please select the customer Payment Type.' }]}>
        {paymentType.length > 0 && (
        <Select
          showSearch
          placeholder="Select Payment Type"
          options={paymentType}
          filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        />
        )}
      </FormAnt.Item>
      <FormAnt.Item name={['select', 'paymentRef']} label="Payment Reference">
        <Input.TextArea />
      </FormAnt.Item>
      <FormAnt.Item name={['select', 'statusId']} label="Payment Status" rules={[{ required: true, message: 'Please select the Payment Status.' }]}>
        <Select
          showSearch
          placeholder="Payment Status"
          optionFilterProp="children"
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          <Option value={1}>Pending</Option>
          <Option value={2}>Confirmed</Option>
          <Option value={4}>Cancelled</Option>
        </Select>
      </FormAnt.Item>
    </FormAnt>
  );
};

Form.propTypes = {
  form: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
  selectedRow: PropTypes.object.isRequired,
};

export default Form;
