/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Card, Col } from 'antd';

const { Meta } = Card;

const StatusCard = ({ counts, label, labelColor }) => (
  <Col span={4}>
    <Card bordered={false} bodyStyle={{ padding: '14px' }} style={{ textAlign: 'center' }}>
      <p style={{
        textAlign: 'center', fontSize: '18px', fontWeight: 700, margin: '5px',
      }}
      >
        {counts}
      </p>
      <p style={{
        margin: '5px', textAlign: 'center', fontSize: '16px', fontWeight: 600, color: labelColor,
      }}
      >
        {label}
      </p>
    </Card>
  </Col>

);

export default StatusCard;
