import React from 'react';
import ConfirmationModel from '_components/ConfirmationModel';
import { useDelete } from '_service/treatmentType';
import { useGetState } from './useStateCompose';

const Confirmation = () => {
  const [isConfirmationModel, toggleConfirmation, confirmation, selectedRow] = useGetState();
  const onError = () => {
    toggleConfirmation();
  };
  const OnSuccess = () => {
    toggleConfirmation();
  };
  const { mutate, isLoading } = useDelete(OnSuccess, onError);
  const onOk = () => {
    mutate({ id: selectedRow.id });
  };
  return (
    <div>
      <ConfirmationModel
        title={confirmation.title}
        bodyText={confirmation.bodyText}
        handleOk={onOk}
        handleCancel={toggleConfirmation}
        confirmLoading={isLoading}
        visible={isConfirmationModel}
      />
    </div>
  );
};

export default Confirmation;
