/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import {
  Form as FormAnt,
  Input,
  Select,
} from 'antd';
import PropTypes from 'prop-types';
import { DatePicker } from '_components/DateControls';
import { FORMAT, instanceSubtract } from '_utils/date-helper';
import { usePaymentTypeDropDown } from '_service/paymentType';

const { Option } = Select;
const Form = ({
  form, onFinish,
}) => {
  const { data: paymentType = [] } = usePaymentTypeDropDown({ size: 1000 });

  return (
    <FormAnt
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 14,
      }}
      layout="horizontal"
      form={form}
      name="Customer"
      onFinish={onFinish}
      spellCheck={false}
    >
      <FormAnt.Item
        name={['Customer', 'fullName']}
        rules={[{ required: true, message: 'Customer Name required!' }]}
        whitespace={false}
        label="Name"
      >
        <Input />
      </FormAnt.Item>
      <FormAnt.Item
        name={['Customer', 'phoneNumber']}
        whitespace={false}
        label="Phone Number"
      >
        <Input spellCheck={false} />
      </FormAnt.Item>
      <FormAnt.Item
        name={['Customer', 'email']}
        rules={[{ type: 'email', message: 'Not a valid email !' }]}
        whitespace={false}
        label="Email"
      >
        <Input spellCheck={false} />
      </FormAnt.Item>
      <FormAnt.Item
        name={['Customer', 'dob']}
        label="DOB"
        whitespace={false}
      >
        <DatePicker
          allowClear
          format={FORMAT.YYYYMMDD}
          defaultPickerValue={instanceSubtract(40)}
        />
      </FormAnt.Item>
      <FormAnt.Item
        name={['Customer', 'customerNumber']}
        whitespace={false}
        label="Customer Number"
      >
        <Input spellCheck={false} />
      </FormAnt.Item>
      <FormAnt.Item name={['Customer', 'customerType']} label="Customer Type" rules={[{ required: true, message: 'Please select the Customer Type.' }]}>
        <Select
          showSearch
          placeholder="Customer Type"
          optionFilterProp="children"
        >
          <Option value={0}>Individual</Option>
          <Option value={1}>Company</Option>
        </Select>
      </FormAnt.Item>
      <FormAnt.Item
        name={['Customer', 'company']}
        whitespace={false}
        label="Company Name"
      >
        <Input spellCheck={false} />
      </FormAnt.Item>
      <FormAnt.Item
        name={['Customer', 'notes']}
        whitespace={false}
        label="Notes"
      >
        <Input.TextArea />
      </FormAnt.Item>
      <FormAnt.Item name={['Customer', 'PaymentTypeId']} label="Preferred Payment Type">
        <Select
          showSearch
          placeholder="Select Payment Type"
          options={paymentType}
        />
      </FormAnt.Item>
      <FormAnt.Item
        name={['Customer', 'paymentStartDate']}
        label="Payment Type Start Date"
        whitespace={false}
      >
        <DatePicker
          allowClear
          format={FORMAT.YYYYMMDD}
        />
      </FormAnt.Item>
      <FormAnt.Item
        name={['Customer', 'paymentEndDate']}
        label="Payment Type End Date"
        whitespace={false}
      >
        <DatePicker
          allowClear
          format={FORMAT.YYYYMMDD}
        />
      </FormAnt.Item>
      <FormAnt.Item
        name={['Customer', 'paymentTerms']}
        whitespace={false}
        label="Payment terms"
      >
        <Input spellCheck={false} />
      </FormAnt.Item>
      <FormAnt.Item
        name={['Customer', 'billingAddress']}
        whitespace={false}
        label="Billing Address"
      >
        <Input.TextArea />
      </FormAnt.Item>
    </FormAnt>
  );
};

Form.propTypes = {
  form: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default Form;
