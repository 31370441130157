/* eslint-disable max-len */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Select,
  InputNumber,
  Button,
  PageHeader,
  Typography,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker } from '_components/DateControls';
import { dateFormat, instanceDayjs, FORMAT } from '_utils/date-helper';
import { useHistory, useParams } from 'react-router-dom';
import DebounceSelect from '_components/Search';
import { Search } from '_api/customer';
import { Search as promoSearch } from '_api/promo-code';
import { SearchFetch as locationFetch } from '_api/location';
import { useTreatmentType } from '_service/treatmentType';
import { usePaymentTypeDropDown } from '_service/paymentType';
import { useCreateOrder, useGetOrderById } from '_service/Order';
import { getDiscountPrice, getPrice, getVat } from 'utilities';
import { isToggleFormModelFn } from '_store/Customer';
import { setCustomer } from '_store/user';

const { Text } = Typography;

const { Option } = Select;
const FormAnt = () => {
  const { data: treatmentType = [], isFetched: treatmentFetched } = useTreatmentType({ size: 1000 });
  const history = useHistory();
  const [form] = Form.useForm();
  const [orderId, setOrder] = useState(null);
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();
  const { id: orderIdP } = useParams();
  const { data: { response = {} } = {}, isFetching, isFetched } = useGetOrderById({ id: orderIdP });
  const { data } = response;
  const setCustomerFn = (filter) => dispatch(setCustomer(filter));
  const customer = useSelector(
    (state) => state.user.customer,
  );
  const onSuccessCall = (result) => {
    const { id } = result;
    if (id) {
      setCustomerFn(user);
      history.push('/orders-groups');
    }
  };
  const { mutate } = useCreateOrder(onSuccessCall);
  const { data: paymentType = [] } = usePaymentTypeDropDown({ size: 1000 });
  const discount = Form.useWatch(['list', 'discountpre'], form);
  const ownLocationPriceW = Form.useWatch(['orderList', 'ownLocationPrice'], form);
  const clientLocationPriceW = Form.useWatch(['orderList', 'clientLocationPrice'], form);
  const durationW = Form.useWatch(['order', 'duration'], form);
  const locationType = Form.useWatch(['orderList', 'locationType'], form);
  const treatmentNumberW = Form.useWatch(['order', 'treatmentNumber'], form);
  const billedAmountPriceW = Form.useWatch(['order', 'billedAmountPrice'], form);
  const customerTypeV = Form.useWatch(['user', 'customerType'], form);
  const Vat = Form.useWatch(['orderList', 'vatRate'], form);
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  const toggleIsToggleFormModelFn = () => dispatch(isToggleFormModelFn());
  const onReset = () => {
    form.resetFields();
  };
  const valuesNd = getPrice(locationType === 1 ? clientLocationPriceW : ownLocationPriceW, treatmentNumberW, durationW);
  const vatArray = getVat(billedAmountPriceW, Vat, customerTypeV);
  useEffect(() => {
    if (valuesNd) {
      form.setFieldsValue({
        order: { actualAmount: valuesNd, billedAmountPrice: discount ? getDiscountPrice(valuesNd, discount) : valuesNd },
      });
    }
  }, [valuesNd, discount]);
  const onFinish = (values) => {
    const { startFrom } = values.order;
    const payload = {
      ...values.order,
      startFrom: startFrom && dateFormat(startFrom.$d, FORMAT.YYYYMMMDD),
      paymentStatus: 1,
      vatAmount: vatArray[2] ? vatArray[2] : 0,
      vat: vatArray[1] ? vatArray[1] : 0,
      billedAmountVat: vatArray[0] ? vatArray[0] : billedAmountPriceW,
    };
    mutate(payload, form.resetFields());
  };
  const [fields, setFields] = useState();
  useEffect(() => {
    const { obj = null } = customer || {};
    if (obj) {
      const { value: values = '{"email":"", "customerType":"", "billingAddress":"" , "phoneNumber":""}' } = obj || {};
      const {
        id, billingAddress = '', email = '', phoneNumber,
        customerType,
        paymentType: cusPaymentType = null,
        fullName = null,
      } = JSON.parse(values) || {};
      setFields([
        {
          name: ['user', 'CustomerDetailId'],
          value: { label: fullName, value: id },
        },
        {
          name: ['order', 'customerId'],
          value: id || null,
        },
        {
          name: ['order', 'paymentTypeId'],
          value: cusPaymentType?.id,
        },
        {
          name: ['user', 'billing_address'],
          value: billingAddress || '',
        },
        {
          name: ['user', 'email'],
          value: email || '',
        },
        {
          name: ['user', 'phoneNumber'],
          value: phoneNumber || '',
        },
        {
          name: ['user', 'customerType'],
          value: customerType || '',
        }]);
      setUser({ label: fullName, value: id, obj });
      setOrder(id || null);
    }
  }, []);
  useEffect(() => {
    if (isFetched && treatmentFetched) {
      if (data) {
        const selectedRow = data || {};
        const { customer: customerP } = selectedRow;
        setFields([
          {
            name: ['user', 'CustomerDetailId'],
            value: { label: customerP.fullName, value: customerP.id },
          },
          {
            name: ['order', 'customerId'],
            value: customerP.id || null,
          },
          {
            name: ['order', 'paymentTypeId'],
            value: selectedRow?.paymentTypeId,
          },
          {
            name: ['user', 'billing_address'],
            value: customerP.billingAddress || '',
          },
          {
            name: ['user', 'email'],
            value: customerP.email || '',
          },
          {
            name: ['user', 'phoneNumber'],
            value: customerP.phoneNumber || '',
          },
          {
            name: ['user', 'customerType'],
            value: customerP.customerType || '',
          },
          {
            name: ['orderList', 'treatmentType'],
            value: selectedRow?.treatmentType ? { label: selectedRow?.treatmentType?.title, value: selectedRow?.treatmentType?.id } : null,
          },
          {
            name: ['order', 'treatmentTypeId'],
            value: selectedRow?.treatmentType?.id || null,
          },
          {
            name: ['orderList', 'ownLocationPrice'],
            value: selectedRow?.treatmentType?.ownLocationPrice || 0,
          },
          {
            name: ['orderList', 'clientLocationPrice'],
            value: selectedRow?.treatmentType?.clientLocationPrice || 0,
          },
          {
            name: ['orderList', 'vatRate'],
            value: selectedRow?.treatmentType?.vatRate || 0,
          },
          {
            name: ['order', 'source'],
            value: selectedRow?.source || null,
          },
          {
            name: ['order', 'treatmentNumber'],
            value: selectedRow?.treatmentNumber || null,
          },
          {
            name: ['order', 'duration'],
            value: selectedRow?.duration || null,
          },
          {
            name: ['order', 'locationId'],
            value: selectedRow?.location?.id || null,
          },
          {
            name: ['orderList', 'locationId'],
            value: selectedRow?.location ? { label: selectedRow?.location?.title, value: selectedRow?.location?.id } : null,
          },
          {
            name: ['orderList', 'locationType'],
            value: selectedRow?.location?.flag || '',
          },
          {
            name: ['order', 'locationAddress'],
            value: selectedRow.location ? selectedRow?.locationAddress : '' || '',
          },
          {
            name: ['order', 'promoCodeId'],
            value: selectedRow?.promoCode?.id || null,
          },
          {
            name: ['order', 'startFrom'],
            value: selectedRow.startFrom
              ? instanceDayjs(selectedRow.startFrom)
              : null,
          },
          {
            name: ['list', 'promoCode'],
            value: selectedRow?.promoCode ? { label: selectedRow?.promoCode?.title, value: selectedRow.promoCode?.id } : null,
          },
          {
            name: ['list', 'discountpre'],
            value: selectedRow?.promoCode?.discountpre || '',
          },
          {
            name: ['list', 'code'],
            value: selectedRow?.promoCode?.code || '',
          },
          {
            name: ['order', 'statusId'],
            value: selectedRow.status?.id || null,
          },
          {
            name: ['order', 'paymentRef'],
            value: selectedRow?.paymentRef || '',
          },
        ]);
      } else if (treatmentType.length > 0) {
        const { value: selectedRow = '' } = treatmentType[0] || {};
        const {
          id = null, ownLocationPrice = 0, clientLocationPrice = 0, vatRate = 0, title,
        } = JSON.parse(selectedRow) || {};
        setFields([
          {
            name: ['orderList', 'treatmentType'],
            value: { label: title, value: id } || null,
          },
          {
            name: ['order', 'treatmentTypeId'],
            value: id || null,
          },
          {
            name: ['orderList', 'ownLocationPrice'],
            value: ownLocationPrice || 0,
          },
          {
            name: ['orderList', 'clientLocationPrice'],
            value: clientLocationPrice || 0,
          },
        ]);
      }
    }
  }, [data, isFetched, treatmentFetched]);

  return (
    <Form
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 14,
      }}
      layout="horizontal"
      onFinish={onFinish}
      form={form}
      fields={fields}
    >
      <PageHeader
        ghost={false}
        title="Customer"
        style={{ marginTop: 24 }}
      >
        <Form.Item noStyle label="" name={['order', 'customerId']} />
        <Form.Item noStyle label="" name={['user', 'customerType']} />
        <Form.Item label="Customer" name={['user', 'CustomerDetailId']} rules={[{ required: true, message: 'Please select the customer.' }]}>
          <DebounceSelect
            showSearch
            allowClear
            placeholder="search customer"
            defaultValue={customer?.label || null}
            fetchOptions={Search}
            style={{ width: 200 }}
            onChange={(newValue) => {
              const { value: values = '{"email":"", "customerType":"", "billingAddress":"" , "phoneNumber":""}' } = newValue || {};
              const {
                id, billingAddress = '', email = '', phoneNumber,
                customerType,
                paymentType: cusPaymentType = null,
                fullName = null,
              } = JSON.parse(values) || {};
              form.setFieldsValue({
                user: {
                  billing_address: billingAddress,
                  email,
                  phoneNumber,
                  customerType,
                },
                order: {
                  customerId: id,
                  paymentTypeId: cusPaymentType?.id || null,
                },
              });
              setUser({ label: fullName, value: id, obj: newValue });
              setOrder(id || null);
            }}
          />
        </Form.Item>
        <Form.Item name={['user', 'email']} label="Email">
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Phone Number"
          name={['user', 'phoneNumber']}
        >
          <Input disabled style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item name={['user', 'billing_address']} label="Billing Address">
          <Input.TextArea disabled />
        </Form.Item>
      </PageHeader>
      <PageHeader
        ghost={false}
        title="Treatment"
        style={{ marginTop: 24 }}
      >
        <Form.Item name={['order', 'source']} label="Booking Source">
          <Input />
        </Form.Item>
        <Form.Item noStyle name={['order', 'treatmentTypeId']} />
        <Form.Item noStyle name={['orderList', 'clientLocationPrice']} />
        <Form.Item noStyle name={['orderList', 'ownLocationPrice']} />
        <Form.Item noStyle name={['orderList', 'vatRate']} />
        <Form.Item name={['orderList', 'treatmentType']} label="Treatment Type" rules={[{ required: true, message: 'Please select the Treatment Type.' }]}>
          <Select
            showSearch
            placeholder="Select Treatment Type"
            allowClear
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onChange={(newValue) => {
              const {
                id = null, ownLocationPrice = 0, clientLocationPrice = 0, vatRate = 0,
              } = newValue ? JSON.parse(newValue) : {};
              form.setFieldsValue({
                order: { treatmentTypeId: id },
                orderList: { ownLocationPrice, clientLocationPrice, vatRate },
              });
            }}
          >
            {treatmentType.map((item) => (
              <Option key={item.value}>{item.label}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={['order', 'treatmentNumber']}
          label="Number of Treatment"
          rules={[{ required: true, message: 'Please input the Treatment #.' },
            () => ({
              validator(_, value) {
                if (value != null && value <= 0) {
                  return Promise.reject(new Error('Value must be grater then zero'));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name={['order', 'duration']}
          label="Duration"
          rules={[{ required: true, message: 'Please input the Duration.' },
            () => ({
              validator(_, value) {
                if (value != null && value <= 0) {
                  return Promise.reject(new Error('Value must be grater then zero'));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber addonAfter="hours" />
        </Form.Item>
        <Form.Item name={['order', 'startFrom']} label="Start Date" rules={[{ required: true, message: 'Please select the start Data.' }]}>
          <DatePicker
            allowClear
            format={FORMAT.YYYYMMMDD}
          />
        </Form.Item>
        <Form.Item noStyle label="" name={['order', 'locationId']} />
        <Form.Item noStyle label="" name={['orderList', 'locationType']} />
        <Form.Item label="Location" name={['orderList', 'locationId']} rules={[{ required: true, message: 'Please select the location.' }]}>
          <DebounceSelect
            showSearch
            allowClear
            placeholder="search locations"
            fetchOptions={locationFetch}
            style={{ width: 200 }}
            onChange={(newValue) => {
              const { value: values = '{"id":"", "flag":""}' } = newValue || {};
              const {
                id, flag = '',
              } = JSON.parse(values) || {};
              form.setFieldsValue({
                order: { locationId: id },
                orderList: { locationType: flag },
              });
            }}
          />
        </Form.Item>
        {locationType === 1 ? (
          <Form.Item name={['order', 'locationAddress']} label="Client Address">
            <Input.TextArea />
          </Form.Item>
        ) : ''}
      </PageHeader>
      <PageHeader
        ghost={false}
        title="Payment Details"
        style={{ marginTop: 24 }}
      >
        <Form.Item noStyle label="promoCode" name={['order', 'promoCodeId']} />
        <Form.Item label="Promo" name={['list', 'promoCode']}>
          <DebounceSelect
            showSearch
            allowClear
            placeholder="search Promo"
            fetchOptions={promoSearch}
            style={{ width: 200 }}
            onChange={(newValue) => {
              const { value: values = '{"discountpre":"", "code":""}' } = newValue || {};
              const {
                id, discountpre = '', code,
              } = JSON.parse(values) || {};
              form.setFieldsValue({
                order: {
                  promoCodeId: id,
                },
                list: {
                  discountpre,
                  code,
                },
              });
            }}
          />
        </Form.Item>
        <Form.Item name={['list', 'discountpre']} label="Discount %">
          <Input InputNumber disabled />
        </Form.Item>
        <Form.Item
          label="Promo Code"
          name={['list', 'code']}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item name={['order', 'actualAmount']} label="Calculated Price">
          <Text>
            {valuesNd ? `${valuesNd} SEK` : ''}
          </Text>
        </Form.Item>
        <Form.Item name={['order', 'billedAmountPrice']} label="Billing Price">
          <InputNumber
            addonBefore={discount && (
            <Text delete>
              {`${valuesNd || 0}  `}
            </Text>
            )}
            addonAfter="SEK"
          />
        </Form.Item>
        {customerTypeV ? (
          <Form.Item name={['ordersLists', 'odder']} label="VAT">
            <Text>
              {vatArray[2] ? `${vatArray[2]} SEK` : '0 SEK'}
            </Text>
          </Form.Item>
        ) : null}
        <Form.Item name={['orderList', 'odder']} label="Billing Price + VAT">
          <Text>
            {vatArray[0] ? `${vatArray[0]} SEK` : `${billedAmountPriceW || 0} SEK`}
          </Text>
        </Form.Item>
        <Form.Item name={['order', 'paymentTypeId']} label="Payment Type" rules={[{ required: true, message: 'Please select the payment type.' }]}>
          <Select
            showSearch
            placeholder="Select Payment Type"
            optionFilterProp="children"
            options={paymentType}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
          </Select>
        </Form.Item>
        <Form.Item name={['order', 'statusId']} label="Payment Status" rules={[{ required: true, message: 'Please select the payment status.' }]}>
          <Select
            showSearch
            placeholder="Payment Status"
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            <Option value={1}>Pending</Option>
            <Option value={2}>Confirmed</Option>
            <Option value={4}>Cancelled</Option>
          </Select>
        </Form.Item>
        <Form.Item name={['order', 'paymentRef']} label="Payment Reference">
          <Input.TextArea />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button htmlType="button" onClick={onReset}>
            Reset
          </Button>
          <Button type="primary" style={{ marginLeft: 20 }} htmlType="submit">
            Create Order
          </Button>
        </Form.Item>
      </PageHeader>
    </Form>
  );
};

export default FormAnt;
