/* eslint-disable max-len */
import React from 'react';
import {
  Row, Col, Spin,
} from 'antd';
import {
  useParams,
} from 'react-router-dom';
import { useGetOrderById } from '_service/Order';
import Detail from './Detail';
import OrderDetailForm from '../OrderDetailForm';
import ChangeCustomerForm from '../ChangeCustomerForm';
import PaymnetDetailForm from '../PaymnetDetailForm';
import PaymnetForm from '../PaymnetForm';
import Table from './Table';

const OrderDetail = () => {
  const { id } = useParams();
  const { data, isFetching } = useGetOrderById({ id });
  return (
    <>
      <ChangeCustomerForm />
      <PaymnetDetailForm />
      <OrderDetailForm />
      <PaymnetForm />
      <Row>
        <Col span={20} offset={2}>

          {isFetching ? <Spin size="large" /> : null}
          {data && (<Detail data={data?.response?.data || {}} />)}
          {id && <Table data={data?.response?.data || {}} />}
        </Col>
      </Row>
    </>
  );
};

export default OrderDetail;
