/* eslint-disable no-unused-vars */
import React from 'react';
import { Layout } from 'antd';
import From from './Form';
import SubRoutes from './SubRoutes';

const AddOrder = () => (
  <Layout style={{ padding: '16px 24px' }}>
    <SubRoutes />
  </Layout>
);

export default AddOrder;
