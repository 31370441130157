/* eslint-disable no-unused-vars */
/* eslint-disable no-sequences */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  adjustmentType: null,
  isOpenStatusEditModel: false,
  isOpenEmailModel: false,
  isOpenInvoiceModel: false,
  isOpenAdjustmentModel: false,
  isOpenTabAdjustmentModel: false,
  selectedRows: [],
  selectedAll: false,
  isBulk: false,
  selectedRow: [],
  selectedPartner: {}, // need to verity
  subRouter: {},
  selectedStatus: null,
  currentStatus: null,
  editInvoicesIds: [],
  filters: {
    partner_id: null,
    date_interval_id: null,
    invoiceStatus: null,
    page: 1,
    refetch: false,
    // invoice_status:
    // {
    //   id: null,
    // },
  },
  tabCount: {},
  IsToggleOrderAddModel: true,
  ///
};
const PartnerInvoiceReducer = createSlice({
  name: 'InvoiceListReducer',
  initialState,
  reducers: {
    toggleOrderAddModel: (state) => {
      state.IsToggleOrderAddModel = !state.IsToggleOrderAddModel;
    },
    toggleStatusEditModel: (state, action) => {
      const {
        isBulk = false, editInvoicesIds = [], currentStatus = null, selectedRow = {},
      } = action.payload || {};
      state.isBulk = isBulk;
      state.selectedRow = selectedRow;
      state.currentStatus = currentStatus;
      state.editInvoicesIds = editInvoicesIds;
      state.isOpenStatusEditModel = !state.isOpenStatusEditModel;
    },
    toggleInvoiceModel: (state, action) => {
      state.isOpenInvoiceModel = !state.isOpenInvoiceModel;
      state.selectedPartner = action.payload || null;
    },
    toggleAdjustmentModel: (state, action) => {
      state.isOpenAdjustmentModel = !state.isOpenAdjustmentModel;
      state.selectedPartner = action.payload ? action.payload[0] : null;
      state.adjustmentType = action.payload ? action.payload[1] : null;
    },
    toggleTabAdjustmentModel: (state, action) => {
      state.selectedRow = action?.payload || {};
      state.isOpenTabAdjustmentModel = !state.isOpenTabAdjustmentModel;
    },
    setFilter: (state, action) => {
      const filter = { ...action.payload, ...{ refetch: !state?.refetch } };
      state.filters = filter;
      state.selectedRows = [];
      state.selectedAll = false;
    },
    setChangeStatus: (state, action) => {
      const { filters = initialState.filters, selectedStatus = null } = { ...action.payload } || {};
      state.filters = { ...filters, ...{ page: 1, email_status: '' } };
      state.selectedStatus = selectedStatus;
      state.selectedRows = [];
      state.selectedAll = false;
    },
    setFilterOnRoute: (state, action) => {
      const { id = null, startDate = null, endDate = null } = action.payload;
      const filter = id
        ? { ...initialState.filters, ...{ date_interval_id: id } } : initialState.filters;
      state.filters = filter;
      state.subRouter = { startDate, endDate },
      state.selectedRows = [];
      state.selectedAll = false;
    },
    setSelectedRows: (state, action) => {
      const { selectedRows = [], selectedAll = false } = action.payload || {};
      state.selectedRows = selectedRows;
      state.selectedAll = selectedAll;
    },
    toggleEmailModel: (state, action) => {
      const {
        isBulk = false, editInvoicesIds = [], selectedRow = {},
      } = action.payload || {};
      state.isBulk = isBulk;
      state.selectedRow = selectedRow;
      state.editInvoicesIds = editInvoicesIds;
      state.isOpenEmailModel = !state.isOpenEmailModel;
    },
    reset: (state) => initialState,
  },
});

export const {
  toggleStatusEditModel,
  toggleInvoiceModel,
  toggleUploadAdjustmentModel,
  setFilter,
  setSelectedRows,
  reset,
  toggleEmailModel,
  toggleAdjustmentModel,
  setFilterOnRoute,
  toggleTabAdjustmentModel,
  setChangeStatus,
  toggleOrderAddModel,
} = PartnerInvoiceReducer.actions;

export default PartnerInvoiceReducer.reducer;
