/* eslint-disable no-unused-vars */
import React from 'react';
import { Row, Col, Layout } from 'antd';
import DataTable from './tableView';
import Filter from './Filter';
import AddForm from './AddForm';
import EditForm from './EditForm';
import Confirmation from './Confirmation';

const Customer = () => (
  <Layout style={{ padding: '16px 24px' }}>
    <Confirmation />
    <AddForm />
    <EditForm />
    <Filter />
    <DataTable />
  </Layout>
);

export default Customer;
