export const { REACT_APP_BASE_URL } = process.env;
// export const { SERVER_PORT } = process.env;
export const BASE_URL = REACT_APP_BASE_URL;
export const ENDPOINTS = {
  LOGIN: `${REACT_APP_BASE_URL}auth/login`,
  LOGOUT: `${REACT_APP_BASE_URL}auth/login`,
  FETCH_PARTNER: `${REACT_APP_BASE_URL}invoice/`,
  FETCH_INVOICE_TYPE: `${REACT_APP_BASE_URL}invoice-type/`,
  FETCH_INVOICE_STATUS: `${REACT_APP_BASE_URL}invoice-status/`,
  CHANGE_INVOICE_STATUS: `${REACT_APP_BASE_URL}invoice-status-update/`,
  EXEMPT_TAB: `${REACT_APP_BASE_URL}exempt-tab/`,
  SEND_EMAIL: `${REACT_APP_BASE_URL}send-email/`,
  ADJUSTMENT_FILE_UPLOAD: `${REACT_APP_BASE_URL}Adjustment-File/`,
  ORDER_DETAIL_REPORT: `${REACT_APP_BASE_URL}order-report/`,
  FETCH_REPORT_TYPE: `${REACT_APP_BASE_URL}reports/report-type/`,
  GENERATE_REPORT: `${REACT_APP_BASE_URL}reports/`,
  FETCH_PARTNER_SEARCH: `${REACT_APP_BASE_URL}partner-search/`,
  INVOICE_CYCLE: `${REACT_APP_BASE_URL}invoice-interval/`,
  CHANGE_INVOICE_STATUS_BULK: `${REACT_APP_BASE_URL}bulk-status-update/`,
  ADD_ADJUSTMENT: `${REACT_APP_BASE_URL}invoice/`,
  GENERATE_REPORT_PAYMENT: `${REACT_APP_BASE_URL}payment-report/`,
  CUSTOMER: `${REACT_APP_BASE_URL}customer`,
  TREATMENT_TYPE: `${REACT_APP_BASE_URL}treatment-type`,
  PROMO_CODE: `${REACT_APP_BASE_URL}promo-code`,
  LOCATION: `${REACT_APP_BASE_URL}location`,
  PAYMENT_TYPE: `${REACT_APP_BASE_URL}payment-type`,
  ORDER: `${REACT_APP_BASE_URL}orders`,
  TREATMENT: `${REACT_APP_BASE_URL}treatment`,
  PROVIDER: `${REACT_APP_BASE_URL}provider`,
  TREATMENT_INVOICE: `${REACT_APP_BASE_URL}treatment/invoice`,
  ORDER_CUSTOMER: `${REACT_APP_BASE_URL}orders/customer_order`,
  TREATMENT_CUSTOMER: `${REACT_APP_BASE_URL}treatment/recent`,
  TREATMENT_CUSTOMER_GROUP: `${REACT_APP_BASE_URL}treatment/customer`,
};

export const REQUEST_TYPES = {
  DELETE: 'DELETE',
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
};
