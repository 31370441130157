/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useGet } from '_service/treatment';
import {
  isToggleEditFormModelFn, setFilter, isToggleDeleteModelFn, reset,
} from '_store/Treatment';

export const useTableView = () => {
  const dispatch = useDispatch();
  const { data, isFetching } = useGet();
  const {
    response, statusCount, totalBookingPrice, totalCancelledPrice, totalProvider,
  } = data || {};
  // const query = queryClient.findAll(QUERY_KEY.TREATMENT);
  useEffect(() => () => { dispatch(reset()); }, []);
  // console.log(data, 'data');
  // console.log(response, 'response');
  const filters = useSelector(
    (state) => state.Treatment.filters,
  );
  const selectedRows = useSelector(
    (state) => state.Treatment.selectedRows,
  );
  const selectedAll = useSelector(
    (state) => state.Treatment.selectedAll,
  );
  const setFilterFn = (filter) => dispatch(setFilter(filter));
  const isToggleEditFormModel = (values) => dispatch(isToggleEditFormModelFn(values));
  const isToggleDeleteModel = (values) => dispatch(isToggleDeleteModelFn(values));
  return {
    selectedRows,
    filters,
    dataSource: response?.data || [],
    isDataSourceFetching: isFetching,
    total: response?.results || 0,
    statusCount,
    selectedAll,
    setFilterFn,
    isToggleEditFormModel,
    isToggleDeleteModel,
    totalBookingPrice,
    totalCancelledPrice,
    totalProvider,
  };
};
