/* eslint-disable no-unused-vars */
import React from 'react';
import { Row } from 'antd';
import InvoiceTable from './Table';
import StatusCard from '../Components/StatusCard';
import Filter from './Filter';
import ChangeStatusForm from '../ChangeStatusForm';
import './index.css';

const InvoiceList = () => (
  <>
    <ChangeStatusForm />
    <Filter />
    <InvoiceTable />
  </>

);

export default InvoiceList;
