/* eslint-disable no-undef */
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setLoginSuccess } from '_store/user';
import { message as toast } from 'antd';
import { reset } from '_store/order';
import { loginRequest } from '_api/user';

export const useLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  return useMutation(loginRequest, {
    onSuccess: (data) => {
      const { accessToken, user } = data || {};
      const result = { ...user, accessToken };
      dispatch(setLoginSuccess(result));
      history.replace('/dashboard');
    },
    onError: () => {
      toast.error('Unauthorized user');
    },
  });
};

export const useLoginOut = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  history.replace('/');
  dispatch(reset());
  dispatch(setLoginSuccess(null));
};
