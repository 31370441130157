/* eslint-disable react/prop-types */
import React from 'react';
import { Button } from 'antd';
import './ButtonAtn.styles.less';
// import PropTypes from 'prop-types';

const ButtonAtn = ({
  text, onclick, htmlType, type, style, loading,
}) => (
  <Button loading={loading} className="btn-grad" type={type} htmlType={htmlType} onClick={onclick} style={style}>
    {text}
  </Button>
);

export default ButtonAtn;
