/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Form,
  Button,
  Typography,
  Select,
  Row,
  Col,
  Spin,
  Alert,
  message as toast,
} from 'antd';
import './reports.css';
import { DatePicker } from '_components/DateControls';
import { usePaymentTypeDropDown } from '_service/paymentType';
import { useProviderDropDown } from '_service/provider';
import { useLocationTypeDropDown } from '_service/location';
import DebounceSelect from '_components/Search';
import { Search } from '_api/customer';
import {
  dateISOFormatStart, dateISOFormatEnd, dateFormat, FORMAT,
} from '_utils/date-helper';
import { getInvoice } from '_api/treatment';
import xlsx from 'json-as-xlsx';

const { Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};
const FormComponent = ({ onFnSubmit }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const getJson = (List) => {
    const data = [
      {
        sheet: '',
        columns: [
          { label: 'id', value: 'id' },
          { label: 'Name', value: 'ordersCustomerFullName' }, // Top level data
          { label: 'Company Name', value: 'ordersCustomerCompany' }, // Custom format
          { label: 'Number', value: 'ordersCustomerCustomerId' },
          { label: 'Email', value: 'ordersCustomerEmail' },
          { label: 'Address', value: 'ordersCustomerBillingAddress' },
          { label: 'Notes', value: 'ordersCustomerNotes' },
          { label: 'Phone Number', value: 'ordersCustomerPhoneNumber' },
          { label: 'Treatment', value: 'treatmentTypeTitle' },
          { label: 'Location', value: 'ordersLocationTitle' },
          { label: 'Therapist', value: 'providersName' },
          { label: 'Date', value: (row) => `${dateFormat(row.treatmentDatetime, FORMAT.YYYYMMDDHHMM)}` },
          { label: 'hours', value: (row) => `${row.duration || row.ordersDuration}` },
          { label: 'Price', value: 'providerPrice' },
          { label: 'Payment', value: 'ordersCustomerPaymentTypeTitle' },
          { label: 'Payment Terms', value: 'ordersCustomerPaymentTerms' },
        ],
        content: List,
      },
    ];
    const settings = {
      fileName: 'Customer Treatments exact',
      extraLength: 3,
      writeOptions: {},
    };
    console.log(data, 'data');
    xlsx(data, settings);
    setLoading(false);
  };
  const getAnswer = async (payload) => {
    setLoading(true);
    const data = await getInvoice(payload);
    if (data) { getJson(data); } else {
      setLoading(false);
      toast.error('Records not founds');
    }
  };

  const handleSubmit = (values) => {
    const payload = {
      ...values.order,
      startDate: dateISOFormatStart(values.RangePicker?.Range[0].$d),
      endDate: dateISOFormatEnd(values.RangePicker?.Range[1].$d),
    };
    getAnswer(payload);
  };
  const { data: paymentType = [] } = usePaymentTypeDropDown({ size: 1000 });
  const { data: provider = [] } = useProviderDropDown({});
  const { data: location = [] } = useLocationTypeDropDown({});

  console.log(provider, 'provider');
  return (
    <Spin tip="Loading..." spinning={loading}>
      <Row justify="center" align="middle" className="report-wrapper">
        <Col span={10} className="report-container">
          <Form.Item {...{ wrapperCol: { offset: 8, span: 16 } }}>
            <Title level={3}>Generate Extract</Title>
          </Form.Item>
          <Form
            name="ReportForm"
            form={form}
            labelCol={{
              span: 6,
            }}
            wrapperCol={{
              span: 14,
            }}
            onFinish={handleSubmit}
          >
            <Form.Item
              name={['RangePicker', 'Range']}
              label="Date"
              rules={[
                {
                  required: true,
                  message: 'Please select date range!',
                },
              ]}
            >
              <RangePicker
                style={{ width: '100%' }}
                format="DD/MM/YYYY"
                id="startDate"
              />
            </Form.Item>
            <Form.Item label="Payment Type" name={['order', 'paymentTypeId']}>
              <Select
                showSearch
                placeholder="Select Payment Type"
                optionFilterProp="children"
                allowClear
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {paymentType.map((item) => (
                  <Option key={item.value}>{item.label}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Provider" name={['order', 'providerId']}>
              <Select
                showSearch
                placeholder="Select provider"
                optionFilterProp="children"
                allowClear
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {provider.map((item) => (
                  <Option key={item.value}>{item.label}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Location" name={['order', 'locationId']}>
              <Select
                showSearch
                placeholder="Select location"
                optionFilterProp="children"
                allowClear
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {location.map((item) => (
                  <Option key={item.value}>{item.label}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item noStyle label="" name={['order', 'customerId']} />
            <Form.Item label="Customer" name={['user', 'customerIdType']}>
              <DebounceSelect
                showSearch
                allowClear
                placeholder="search customer"
                fetchOptions={Search}
                onChange={(newValue) => {
                  const { value: values = '{"email":"", "billingAddress":"" , "phoneNumber":""}' } = newValue || {};
                  const {
                    id, phoneNumber,
                  } = JSON.parse(values) || {};
                  form.setFieldsValue({
                    order: { customerId: id },
                  });
                }}
              />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button style={{ width: '100%' }} type="primary" htmlType="submit">
                Generate
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Spin>
  );
};

FormComponent.propTypes = {
  onFnSubmit: PropTypes.func,
};

export default FormComponent;
