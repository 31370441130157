/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React from 'react';
import {
  Table, Space,
} from 'antd';
import { useTableView } from './useStateCompose';

const DataTable = () => {
  const {
    filters,
    isDataSourceFetching,
    total,
    dataSource,
    setFilterFn,
    isToggleEditFormModel,
    isToggleDeleteModel,
  } = useTableView();

  const handlePageChange = (page) => {
    const updateFilter = { ...filters, ...{ page: page - 1 } };
    setFilterFn(updateFilter);
  };

  const columns = [
    {
      dataIndex: 'title',
      title: 'Title',
      width: '20%',
      ellipsis: true,
    },
    {
      dataIndex: 'ownLocationPrice',
      title: 'Own Location',
      width: '20%',
      ellipsis: true,
    },
    {
      dataIndex: 'clientLocationPrice',
      title: 'Client Location',
      width: '20%',
      ellipsis: true,
    },
    {
      dataIndex: 'vatRate',
      title: 'VAT Rate',
      width: '20%',
      ellipsis: true,
    },
    {
      dataIndex: 'id',
      title: '',
      width: '10px',
      ellipsis: true,
      render: (_, row) => (
        <Space size="middle">
          <a onClick={() => { isToggleEditFormModel({ selectedRow: row }); }}>Edit</a>
          <a onClick={() => { isToggleDeleteModel({ selectedRow: row }); }}>Delete</a>
        </Space>
      ),
    },

  ];
  return (
    <div>
      <Table
        loading={isDataSourceFetching}
        pagination={{
          showSizeChanger: false,
          size: 10,
          total,
          current: filters?.page + 1 || 1,
          defaultCurrent: 1,
          position: ['bottomRight'],
          onChange: handlePageChange,
        }}
        size="small"
        scroll={{ x: 1000, y: 500 }}
        rowKey={(record) => record.id}
     // rowSelection={rowSelection}
        columns={columns}
        dataSource={dataSource}
      />
    </div>
  );
};

export default DataTable;
