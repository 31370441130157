/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React from 'react';
import {
  Table, Button, Space, Typography,
} from 'antd';
import { getPriceFormate, dateFormat, FORMAT } from '_utils/date-helper';
import { useTableView } from './useStateCompose';

const { Text, Link } = Typography;

const DataTable = () => {
  const {
    filters,
    isDataSourceFetching,
    total,
    dataSource,
    setFilterFn,
    isToggleEditFormModel,
    isToggleDeleteModel,
  } = useTableView();

  const handlePageChange = (page) => {
    const updateFilter = { ...filters, ...{ page: page - 1 } };
    setFilterFn(updateFilter);
  };

  const columns = [
    {
      dataIndex: 'id',
      title: 'ID.',
      width: '10px',
      ellipsis: true,
    },
    {
      dataIndex: 'customerNumber',
      title: 'Customer No.',
      width: '20px',
      ellipsis: true,
    },
    {
      dataIndex: 'fullName',
      title: 'Name',
      key: 1,
      ellipsis: true,
      width: '15px',
    },
    {
      dataIndex: 'phoneNumber',
      title: 'Contacts',
      width: '20px',
      render: (_, row) => (
        <Space direction="vertical">
          <Text>
            {row.phoneNumber}
          </Text>
          <Text>
            {row.email}
          </Text>
        </Space>
      ),
    },
    {
      dataIndex: 'dob',
      title: 'DOB',
      width: '17px',
      ellipsis: true,
      render: (dob) => (
        <span>
          {dateFormat(dob, FORMAT.YYYYMMMDD)}
        </span>
      ),
    },
    {
      dataIndex: 'company',
      title: 'Company',
      width: '15px',
      ellipsis: true,
    },
    {
      dataIndex: 'paymentType',
      title: 'PREF Payment',
      width: '20px',
      ellipsis: true,
      render: (paymentType) => (
        <span>{paymentType?.title || ''}</span>),
    },
    {
      dataIndex: 'paymentTerms',
      title: 'Payment terms',
      width: '15px',
      ellipsis: true,
    },
    {
      dataIndex: 'notes',
      title: 'Notes',
      width: '20px',
      ellipsis: true,
    },
    {
      dataIndex: 'billingAddress',
      title: 'Billing Address',
      width: '20px',
      ellipsis: true,
    },
    {
      dataIndex: 'id',
      title: '',
      width: '15px',
      ellipsis: true,
      render: (_, row) => (
        <Space size="middle">
          <a onClick={() => { console.log(row, '44'); isToggleEditFormModel({ selectedRow: row }); }}>Edit</a>
          <a onClick={() => { console.log(row, '44'); isToggleDeleteModel({ selectedRow: row }); }}>Delete</a>
        </Space>
      ),
    },

  ];
  return (
    <div>
      <Table
        loading={isDataSourceFetching}
        pagination={{
          showSizeChanger: false,
          size: 10,
          total,
          current: filters?.page + 1 || 1,
          defaultCurrent: 1,
          position: ['bottomRight'],
          onChange: handlePageChange,
        }}
        size="small"
        scroll={{ x: 100, y: 500 }}
        rowKey={(record) => record.id}
     // rowSelection={rowSelection}
        columns={columns}
        dataSource={dataSource}
      />
    </div>
  );
};

export default DataTable;
