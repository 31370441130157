/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useState } from 'react';
import {
  Table, Button, Row, Tag,
} from 'antd';
import DropdownMenu from '_components/DropdownMenu';
import { getPriceFormate, dateFormat, FORMAT } from '_utils/date-helper';
import { useHistory } from 'react-router-dom';
import { isToggleFormModelFn } from '_store/Treatment';
import { useDispatch } from 'react-redux';
import AddForm from 'pages/Treatment/AddForm';
import { usePartnerInvoice } from './useStateCompose';
import StatusCard from '../../Components/StatusCard';

import './table.css';

const list = [
  { key: 'detail-view', title: 'Detail View' },
];

const getStatus = (st) => {
  switch (st) {
    case 1:
      return 'Pending';
    case 2:
      return 'Confirmed';
    case 4:
      return 'Cancelled';
    default:
      return '-';
  }
};

const DataTable = () => {
  const {
    filters,
    isDataSourceFetching,
    total,
    dataSource,
    setFilterFn,
    statusCount,
    totalCustomer,
    isToggleStatusModelFn,
  } = usePartnerInvoice();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selected, setSelected] = useState({});
  const handlePageChange = (page) => {
    const updateFilter = { ...filters, ...{ page: page - 1 } };
    setFilterFn(updateFilter);
  };

  const onActions = (key, select) => {
    const { id } = select;
    switch (key) {
      case 'detail-view':
        history.push(`/orders/details/${id}`);
        break;
      case 'add-treatment':
        setSelected(select);
        dispatch(isToggleFormModelFn());
        break;
      default:
    }
  };
  const onSave = () => {
    const updateFilter = { ...filters };
    setFilterFn(updateFilter);
  };
  const columns = [
    {
      dataIndex: 'orderNumber',
      title: 'Order No',
      key: 1,
      width: '20px',
    },
    {
      dataIndex: 'startFrom',
      title: 'Date',
      width: '14%',
      ellipsis: true,
      render: (startFrom) => (
        <span>
          {dateFormat(startFrom, FORMAT.YYYYMMMDD)}
        </span>
      ),
    },
    {
      dataIndex: 'customer',
      title: 'Customer',
      width: '20%',
      ellipsis: true,
      render: ({ fullName }) => (
        <span>
          {fullName}
        </span>
      ),
    },
    {
      dataIndex: 'treatmentNumber',
      title: 'Treatments',
      render: (treatmentNumber, row) => (
        <span>
          <Tag color={treatmentNumber === row?.bookedCount ? 'success' : 'processing'}>
            {`${row?.bookedCount || 0} / ${treatmentNumber || 0}` }
          </Tag>
        </span>
      ),
      width: '35px',
    },
    {
      dataIndex: 'billedAmountVat',
      title: 'Price',
      width: '11%',
      render: (billedAmountVat) => (getPriceFormate(billedAmountVat)),

    },
    {
      dataIndex: 'statusId',
      title: 'Status',
      width: '11%',
      render: (statusId, row) => (
        <Button type="dashed" onClick={() => isToggleStatusModelFn({ selectedRows: row })}>{getStatus(statusId)}</Button>
      ),
    },
    {
      dataIndex: 'action',
      title: '',
      width: '20px',
      render: (_, record) => (
        <DropdownMenu
          onMenuClick={(e) => onActions(e && e.key, record)}
          options={list}
        />
      ),
    },
  ];
  return (
    <div>
      <div className="card_list">
        <AddForm data={selected} onSave={onSave} />
        <Row justify="center" gutter={[16, 8]}>
          <StatusCard counts={total} label="Orders" labelColor="#1890ff" />
          <StatusCard counts={totalCustomer} label="Customer" labelColor="black" />
          <StatusCard counts={statusCount?.bookingCount || 0} label="Confirmed" labelColor="#237804" />
          <StatusCard counts={statusCount?.pendingCount || 0} label="Pending" labelColor="#cf1322" />
          <StatusCard counts={statusCount?.cancelCount || 0} label="Cancelled" labelColor="#d46b08" />
        </Row>
      </div>
      <></>
      <Table
        loading={isDataSourceFetching}
        pagination={{
          showSizeChanger: false,
          size: 10,
          total,
          current: filters?.page + 1 || 1,
          defaultCurrent: 1,
          position: ['bottomRight'],
          onChange: handlePageChange,
        }}
        size="small"
        scroll={{ x: 1000, y: 500 }}
        rowKey={(record) => record.id}
        // rowSelection={rowSelection}
        columns={columns}
        dataSource={dataSource}
      />
    </div>
  );
};

export default DataTable;
