/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import * as dayjs from 'dayjs';

export const FORMAT = {
  DDMMMYY: 'DD-MM-YY',
  YYYYMMDD: 'YYYY-MM-DD',
  YY: 'YY',
  YYYYMMMDD: 'YYYY MMM DD',
  YYYYMMMM: 'YYYY MMMM',
  YYYYMMDDHHMM: 'YYYY-MM-DD H:m',
  HM: 'HH:mm',
  MMMDD: 'MMM DD',

};

export const dateFormat = (date, formatValue) => {
  const d = new Date(date);
  if (d instanceof Date) {
    return dayjs(d).format(formatValue);
  }
  return date;
};

export const instanceDayjs = (date) => {
  const d = new Date(date);
  if (d instanceof Date) {
    return dayjs(date);
  }
  return dayjs();
};

export const getStartOfMonth = (date = new Date()) => dayjs(date).startOf('month').toISOString();

export const getEndOfMonth = (date = new Date()) => dayjs(date).endOf('month').toISOString();

export const instanceAddDayjs = (date = new Date(), value = 1) => {
  const d = new Date(date);
  if (d instanceof Date) {
    return dayjs(d).add(value, 'day');
  }
  return dayjs(d).add(value, 'day');
};

export const instanceSubtract = (value = 1) => dayjs().subtract(value, 'year');
export const getTabCount = (key, count) => {
  const {
    adjustmentAdded = 0,
    pending = 0,
    ready = 0,
    sent = 0,
    settled = 0,
  } = count || { };
  switch (key) {
    case 0:
      return `${adjustmentAdded + pending + ready + sent + settled}`;
    case 1:
      return `${pending}`;
    case 2:
      return `${adjustmentAdded}`;
    case 3:
      return `${ready}`;
    case 4:
      return `${sent}`;
    case 5:
      return `${settled}`;
    default:
      return '---';
  }
};

export const isIterable = (object) => object != null && typeof object[Symbol.iterator] === 'function';

export const StateDataTransformations = (list = {}) => ({ ...list, ...{ key: `${list.id}`, notAllowedMoveTo: list.notAllowedMoveTo.split(',') } });

export const filteredNotIncludes = (list, notInArray) => list.filter((e) => !notInArray.includes(e.key));

export const getPriceFormate = (value) => {
  if (value) {
    try {
      const amount = new Intl.NumberFormat().format(value);
      return amount;
    // eslint-disable-next-line no-empty
    } catch (error) {}
  }
  return value;
};

export const dateISOFormat = (date) => {
  const d = new Date(date);
  if (d instanceof Date) {
    return dayjs(d).toISOString();
  }
  return date;
};
export const dateISOFormatStart = (date) => {
  const d = new Date(date);
  if (d instanceof Date) {
    return dayjs(date).startOf('day').toISOString();
  }
  return date;
};
export const dateISOFormatEnd = (date) => {
  const d = new Date(date);
  if (d instanceof Date) {
    return dayjs(d).endOf('day').toISOString();
  }
  return date;
};

export const DefaultFormat = () => [dayjs().startOf('day'), dayjs().endOf('day')];

// export const DefaultStartFormat = () => {
//   console.log(dayjs().startOf('day'), 'start');
//   // return [dateISOFormat(dayjs().startOf('day')), dateISOFormat(dayjs().endOf('day'))];
// };

export const dateISOFormatC = () => [dayjs().startOf('day').toISOString(), dayjs().endOf('day').toISOString()];

export const isSameDay = (date) => {
  const d = new Date(date);
  if (d instanceof Date) {
    return dateFormat(d, FORMAT.MMMDD) === dateFormat(dayjs().startOf('day'), FORMAT.MMMDD);
  }
  return false;
};
