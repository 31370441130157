/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Form as FormAnt,
  Select,
  Input,
  InputNumber,
  Button,
} from 'antd';
import { DatePicker } from '_components/DateControls';
import { dateFormat, FORMAT, instanceDayjs } from '_utils/date-helper';
import { Search } from '_api/treatmentType';
import { Search as locationFetch } from '_api/location';
import DebounceSelect from '_components/SearchInput';

import PropTypes from 'prop-types';

const { Option } = Select;
const Form = ({
  form, onFinish, selectedRow, isToggleFormModel, treatmentType, location,
}) => {
  const [fields, setFields] = useState();
  useEffect(() => {
    setFields([
      {
        name: ['list', 'treatmentTypeId'],
        value: { label: selectedRow?.treatmentType?.title, value: selectedRow.treatmentTypeId } || {},
      },
      {
        name: ['order', 'treatmentNumber'],
        value: selectedRow.treatmentNumber || '',
      },
      {
        name: ['order', 'duration'],
        value: selectedRow.duration || '',
      },
      {
        name: ['list', 'startFrom'],
        value: instanceDayjs(selectedRow.startFrom) || '',
      },
      {
        name: ['list', 'locationId'],
        value: { label: selectedRow?.location?.title, value: selectedRow.locationId } || {},
      },
    ]);
  }, [isToggleFormModel]);

  return (
    <FormAnt
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 14,
      }}
      layout="horizontal"
      form={form}
      name="Change-Status"
      onFinish={onFinish}
      fields={fields}
    >
      <FormAnt.Item label="Treatment Type" name={['list', 'treatmentTypeId']}>
        <DebounceSelect
          showSearch
          allowClear
          placeholder="search Treatment Type"
          fetchOptions={Search}
          style={{ width: 200 }}
        />
      </FormAnt.Item>
      <FormAnt.Item name={['order', 'treatmentNumber']} label="Number of Treatment">
        <InputNumber />
      </FormAnt.Item>
      <FormAnt.Item name={['order', 'duration']} label="Duration">
        <InputNumber addonBefore="hours" />
      </FormAnt.Item>
      <FormAnt.Item name={['list', 'startFrom']} label="start Date">
        <DatePicker
          allowClear
          format={FORMAT.YYYYMMMDD}
        />
      </FormAnt.Item>
      <FormAnt.Item label="Location" name={['list', 'locationId']}>
        <DebounceSelect
          showSearch
          allowClear
          placeholder="search locations"
          fetchOptions={locationFetch}
          style={{ width: 200 }}
        />
      </FormAnt.Item>
    </FormAnt>
  );
};

Form.propTypes = {
  form: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
  selectedRow: PropTypes.object.isRequired,
};

export default Form;
