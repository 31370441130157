import { isToggleCustomerEditModel } from '_store/order';
import { useSelector, useDispatch } from 'react-redux';

export const useStateCompose = () => {
  const dispatch = useDispatch();
  const isToggleFormModel = useSelector(
    (state) => state.Order.IsToggleCustomerEditModel,
  );
  const selectedRow = useSelector(
    (state) => state.Order.selectedRows,
  );
  const toggleFormModelFn = () => dispatch(isToggleCustomerEditModel());
  return { selectedRow, isToggleFormModel, toggleFormModelFn };
};
