/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  Form as FormAnt,
  Input,
  Select,
  InputNumber,
} from 'antd';
import PropTypes from 'prop-types';
import { DatePicker } from '_components/DateControls';
import DebounceSelect from '_components/Search';
import { instanceDayjs, dateFormat, FORMAT } from '_utils/date-helper';
import { Search } from '_api/Provider';
import { useTreatmentTypeDropDown } from '_service/treatmentType';

const { Option } = Select;
const Form = ({
  form, onFinish, selectedRow, isToggleFormModel,
}) => {
  const [fields, setFields] = useState();
  const { data: treatmentType = [] } = useTreatmentTypeDropDown({ size: 1000 });
  const { orders = {} } = selectedRow || {};
  console.log(selectedRow, 'orderorderorder');
  const {
    billedAmountVat, duration = 0, treatmentNumber,
  } = orders || {};
  useEffect(() => {
    setFields([
      {
        name: ['treatment', 'treatmentStatus'],
        value: selectedRow?.treatmentStatus || null,
      },
      {
        name: ['treatment', 'providerPrice'],
        value: selectedRow?.providerPrice || null,
      },
      {
        name: ['treatment', 'duration'],
        value: (selectedRow?.duration || duration) || null,
      },
      {
        name: ['treatment', 'providerId'],
        // eslint-disable-next-line max-len
        value: { label: selectedRow?.providers?.name, value: selectedRow.providerId } || {},
      },
      {
        name: ['treatmentTypeList', 'treatmentTypeId'],
        value: selectedRow?.treatmentType ? { label: selectedRow?.treatmentType?.title, value: selectedRow?.treatmentType?.id } : null,
      },
      {
        name: ['treatment', 'address'],
        value: selectedRow?.address || null,
      },
      {
        name: ['treatment', 'notes'],
        value: selectedRow?.notes || null,
      },
      {
        name: ['treatment', 'treatmentDatetime'],
        value: selectedRow.treatmentDatetime
          ? instanceDayjs(selectedRow.treatmentDatetime)
          : null,
      },
    ]);
  }, [isToggleFormModel]);
  return (
    <FormAnt
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 14,
      }}
      layout="horizontal"
      form={form}
      fields={fields}
      name="Treatment"
      onFinish={onFinish}
      spellCheck={false}
    >
      <FormAnt.Item
        name={['treatment', 'treatmentDatetime']}
        label="Date & Time"
        rules={[{ required: true, message: 'Please select date !' }]}
        whitespace={false}
      >
        <DatePicker
          allowClear
          showTime
          format={FORMAT.YYYYMMDDHHMM}
        />
      </FormAnt.Item>
      <FormAnt.Item name={['treatmentTypeList', 'treatmentTypeId']} label="Treatment Type" rules={[{ required: true, message: 'Please select the Treatment Type.' }]}>
        <Select
          showSearch
          placeholder="Select Treatment Type"
          allowClear
          optionFilterProp="children"
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {treatmentType.map((item) => (
            <Option key={item.value}>{item.label}</Option>
          ))}
        </Select>
      </FormAnt.Item>
      <FormAnt.Item
        name={['treatment', 'duration']}
        label="Duration"
        rules={[{ required: true, message: 'Please input the Duration.' },
          () => ({
            validator(_, value) {
              if (value != null && value <= 0) {
                return Promise.reject(new Error('Value must be grater then zero'));
              }
              return Promise.resolve();
            },
          }),
        ]}
        onChange={(e) => {
          setTimeout(() => {
            try {
              if (e.target.value) {
                const p = billedAmountVat / treatmentNumber || 0;
                const newPrice = (p / duration) * e.target.value;
                form.setFieldsValue({
                  treatment: { providerPrice: newPrice.toFixed(2) },
                });
              }
            } catch (v) {
              console.log(v);
            }
          }, 300);
        }}
      >
        <InputNumber addonAfter="hours" />
      </FormAnt.Item>
      <FormAnt.Item name={['treatment', 'treatmentStatus']} label="Status" rules={[{ required: true, message: 'Please select status.' }]}>
        <Select
          showSearch
          placeholder="Select status"
          optionFilterProp="children"
          disabled={selectedRow?.treatmentStatus === 2}
        >
          <Option value={1}>Booked</Option>
          <Option value={2}> Cancelled </Option>
        </Select>
      </FormAnt.Item>
      <FormAnt.Item label="Provider" name={['treatment', 'providerId']} rules={[{ required: true, message: 'Please select the Provider.' }]}>
        <DebounceSelect
          showSearch
          allowClear
          placeholder="search provider"
          fetchOptions={Search}
          style={{ width: 200 }}
        />
      </FormAnt.Item>
      <FormAnt.Item
        name={['treatment', 'providerPrice']}
        whitespace={false}
        label="Price"
        rules={[{ required: true, message: 'Please input the price' },
          () => ({
            validator(_, value) {
              if (value != null && value <= -1) {
                return Promise.reject(new Error('valid'));
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input type="number" suffix="SEK" />
      </FormAnt.Item>
      <FormAnt.Item
        name={['treatment', 'address']}
        whitespace={false}
        label="Address"
      >
        <Input.TextArea />
      </FormAnt.Item>
      <FormAnt.Item
        name={['treatment', 'notes']}
        whitespace={false}
        label="Notes"
      >
        <Input.TextArea />
      </FormAnt.Item>
    </FormAnt>
  );
};

Form.propTypes = {
  form: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
  selectedRow: PropTypes.object.isRequired,
};

export default Form;
