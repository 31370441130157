import { combineReducers } from 'redux';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import createFilter from 'redux-persist-transform-filter';
import { persistReducer } from 'redux-persist';
import userReducer from './user';
import PartnerInvoiceReducer from './PartnerInvoice';
import InvoiceListReducer from './PartnerInvoice/InvoiceList';
import orderReducer from './order';
import PromoCodeReducer from './promoCode';
import CustomerReducer from './Customer';
import TreatmentTypeReducer from './TreatmentType';
import LocationReducer from './Location';
import TreatmentReducer from './Treatment';
import ProviderReducer from './Provider';
import orderGroupReducer from './orderGroup';

const rootReducer = combineReducers({
  user: userReducer,
  PartnerInvoice: PartnerInvoiceReducer,
  InvoiceList: InvoiceListReducer,
  Order: orderReducer,
  PromoCode: PromoCodeReducer,
  Customer: CustomerReducer,
  TreatmentType: TreatmentTypeReducer,
  Location: LocationReducer,
  Treatment: TreatmentReducer,
  Provider: ProviderReducer,
  orderGroup: orderGroupReducer,
});

const saveUserFilter = createFilter('user', ['user']);
const persistConfig = {
  key: 'account',
  whitelist: ['user'],
  storage,
  transforms: [saveUserFilter],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export default configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['persist/PERSIST'],
    },
  }),
});
