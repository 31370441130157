import { apiCaller } from '../middleware/api-caller';
import { ENDPOINTS, REQUEST_TYPES } from '../middleware/url';

// eslint-disable-next-line no-return-await
export const loginRequest = async (data) => await apiCaller(
  {
    method: REQUEST_TYPES.POST,
    url: ENDPOINTS.LOGIN,
    data,
  },
);

// eslint-disable-next-line no-return-await
export const logoutRequest = async () => await apiCaller(
  {
    method: REQUEST_TYPES.POST,
    url: ENDPOINTS.LOGOUT,
    Auth: true,
  },
);
