/* eslint-disable no-unused-vars */
/* eslint-disable no-sequences */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  instanceDayjs, instanceAddDayjs, dateFormat, FORMAT,
} from '_utils/date-helper';

const initialState = {
  adjustmentType: null,
  isOpenStatusEditModel: false,
  isOpenEmailModel: false,
  isOpenInvoiceModel: false,
  isOpenAdjustmentModel: false,
  isOpenTabAdjustmentModel: false,
  selectedRows: [],
  selectedAll: false,
  isBulk: false,
  selectedRow: [],
  selectedPartner: {}, // need to verity
  subRouter: {},
  selectedStatus: null,
  currentStatus: null,
  editInvoicesIds: [],
  filters: {
    order_number: null,
    promo_code_id: null,
    payment_type_id: null,
    status_id: null,
    start_date: null,
    end_date: null,
    range: 10,
    page: 0,
    size: 10,
    customer_id: null,
    refetch: false,
  },
  tabCount: {},
  IsToggleOrderAddModel: false,
  IsToggleStatusModel: false,
  IsToggleOrderDetailModel: false,
  IsTogglePaymentDetailModel: false,
  IsToggleCustomerEditModel: false,
  IsTogglePriceDetailModel: false,
  ///
};
const OrderReducer = createSlice({
  name: 'orderList',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      const filter = { ...action.payload, ...{ refetch: !state?.refetch } };
      state.filters = filter;
      state.selectedRows = [];
      state.selectedAll = false;
    },
    setSelectedRows: (state, action) => {
      const { selectedRows = [], selectedAll = false } = action.payload || {};
      state.selectedRows = selectedRows;
      state.selectedAll = selectedAll;
    },
    isToggleStatusModel: (state, action) => {
      const { selectedRows = { } } = action.payload || {};
      state.selectedRows = selectedRows;
      state.IsToggleStatusModel = !state.IsToggleStatusModel;
    },
    isToggleOrderDetailModel: (state, action) => {
      const { selectedRows = { } } = action.payload || {};
      state.selectedRows = selectedRows;
      state.IsToggleOrderDetailModel = !state.IsToggleOrderDetailModel;
    },
    isToggleCustomerEditModel: (state, action) => {
      const { selectedRows = { } } = action.payload || {};
      state.selectedRows = selectedRows;
      state.IsToggleCustomerEditModel = !state.IsToggleCustomerEditModel;
    },
    isTogglePaymentDetailModel: (state, action) => {
      const { selectedRows = { } } = action.payload || {};
      state.selectedRows = selectedRows;
      state.IsTogglePaymentDetailModel = !state.IsTogglePaymentDetailModel;
    },
    isTogglePriceDetailModel: (state, action) => {
      const { selectedRows = { } } = action.payload || {};
      state.selectedRows = selectedRows;
      state.IsTogglePriceDetailModel = !state.IsTogglePriceDetailModel;
    },
    reset: (state) => initialState,
  },
});
export const {
  isTogglePaymentDetailModel,
  isToggleCustomerEditModel,
  isToggleStatusModel,
  isToggleOrderDetailModel,
  setFilter,
  setSelectedRows,
  reset,
  isTogglePriceDetailModel,
} = OrderReducer.actions;

export default OrderReducer.reducer;
