/* eslint-disable no-unused-vars */
import { useQuery } from 'react-query';
import { QUERY_KEY } from '_service/Alias';
import { useSelector, useDispatch } from 'react-redux';
import {
  setFilter,
  setSelectedRows,
} from '_store/orderGroup';
import {
  getOrder,
} from '_api/orderGroup';

const fn = () => {};
export const useGetOrder = (select) => {
  const dispatch = useDispatch();
  const { filters } = useSelector(
    (state) => state.orderGroup,
  );
  return useQuery([QUERY_KEY.GET_ORDER_GROUP, filters],
    getOrder, {
      retry: false,
      select,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        dispatch(setSelectedRows({}));
      },
      onError: () => {
        if (filters.page > 1) {
          const updateFilter = { ...filters, ...{ page: 1 } };
          dispatch(setFilter(updateFilter));
        }
      },
    });
};
