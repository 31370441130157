/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import Axios from 'axios';
import * as queryString from 'query-string';
import * as caseConverter from 'change-object-case';
import store from '_store';
import { setLoginSuccess } from '_store/user';
import { cleanParams } from '../../utilities/common';
import { REQUEST_TYPES, BASE_URL } from './url';

export function apiCaller({
  method = REQUEST_TYPES.GET,
  url = '',
  params = {},
  data = {},
  contentType = 'application/json',
  isTransformRequestRequired = true,
  Auth = null,
} = {}) {
  const { accessToken = '' } = (store.getState().user && store.getState().user.user) || {};
  caseConverter.options = { recursive: true, arrayRecursive: true };
  let headers = { 'Content-Type': contentType };
  if (Auth) {
    headers = {
      'x-access-token': accessToken,
      'Content-Type': contentType,
    };
  }
  const { user } = store.getState();
  return Axios({
    method,
    baseURL: BASE_URL,
    url,
    params,
    // eslint-disable-next-line max-len
    paramsSerializer: (queryParams) => queryString.stringify(caseConverter.snakeKeys(cleanParams(queryParams))),
    data,
    transformRequest: [(reqData) => (isTransformRequestRequired
      ? JSON.stringify(caseConverter.toSnake(reqData))
      : reqData)],
    transformResponse: [(respData) => caseConverter.toCamel(respData)],
    headers: {
      ...headers,
    },
    responseType: 'json',
    validateStatus: (status) => status >= 200 && status < 300,
  })
    .then(({ data: resp }) => resp)
    // eslint-disable-next-line consistent-return
    .catch((error) => {
      if (error.response.status === 401 && Auth) {
        store.dispatch(setLoginSuccess(null));
      } else {
        throw error;
      }
    });
}
