/* eslint-disable no-unused-vars */
import { apiCaller } from '../middleware/api-caller';
import { ENDPOINTS, REQUEST_TYPES } from '../middleware/url';

// eslint-disable-next-line no-return-await
export const paymentType = async ({ queryKey }) => {
  try {
    const [_key, params] = queryKey;
    const result = await apiCaller({
      method: REQUEST_TYPES.GET,
      url: ENDPOINTS.PAYMENT_TYPE,
      Auth: true,
      params: { ...params },
    });
    const { response = [] } = result || {};
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
