import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useCallback } from 'react';
import { QUERY_KEY } from '_service/Alias';
import {
  get, create, update, deleteRecord, treatmentType, getByOrderId, getTreatmentByUser,
} from '_api/treatment';
import { useSelector, useDispatch } from 'react-redux';
import {
  setFilter,
  setFilterOrder,
  setSelectedRows,
} from '_store/Treatment';
import { message as toast } from 'antd';

export const useTreatmentTypeDropDown = (params) => useQuery([QUERY_KEY.TREATMENT,
  params],
treatmentType, {
  select: useCallback(
    ({ data = {} }) => data.map((ele) => ({
      label: `${ele.title}`,
      value: ele.id,
    })),
    [],
  ),
});

export const useTreatmentType = (params) => useQuery([QUERY_KEY.TREATMENT,
  params],
treatmentType, {
  select: useCallback(
    ({ data = {} }) => data.map((ele) => ({
      label: `${ele.title}`,
      value: JSON.stringify(ele),
    })),
    [],
  ),
});
const fn = () => {};

export const useGet = (select) => {
  const dispatch = useDispatch();
  const { filters } = useSelector(
    (state) => state.Treatment,
  );
  return useQuery([QUERY_KEY.TREATMENT, filters],
    get, {
      retry: false,
      select,
      refetchOnWindowFocus: false,
      staleTime: 0,
      onSuccess: () => {
        dispatch(setSelectedRows({}));
      },
      onError: () => {
        if (filters.page > 1) {
          const updateFilter = { ...filters, ...{ page: 1 } };
          dispatch(setFilter(updateFilter));
        }
      },
    });
};

export const useGetByOrderId = (orderId) => {
  const dispatch = useDispatch();
  const { filtersOrder } = useSelector(
    (state) => state.Treatment,
  );
  const payload = { ...filtersOrder, ...{ orderId } };
  return useQuery([QUERY_KEY.TREATMENT, payload],
    getByOrderId, {
      retry: false,
      enabled: payload?.orderId > 0,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        dispatch(setSelectedRows({}));
      },
      onError: () => {
        if (filtersOrder.page > 1) {
          const updateFilter = { ...filtersOrder, ...{ page: 1 } };
          dispatch(setFilterOrder(updateFilter));
        }
      },
    });
};
export const useCreate = (onSuccessCall = fn, onErrorCall = fn) => {
  const queryClient = useQueryClient();
  return useMutation(create, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY.GET_ORDER);
      queryClient.invalidateQueries(QUERY_KEY.TREATMENT);
      queryClient.invalidateQueries(QUERY_KEY.GET_ORDER_ID);
      queryClient.invalidateQueries(QUERY_KEY.GET_ORDER_GROUP);
      onSuccessCall();
      toast.success('successfully created');
    },
    onError: () => {
      onErrorCall();
      toast.error('Unable To Create');
    },
  });
};
export const useUpdate = (onSuccessCall = fn, onErrorCall = fn) => {
  const queryClient = useQueryClient();
  return useMutation(update, {
    onSuccess: () => {
      onSuccessCall();
      queryClient.invalidateQueries(QUERY_KEY.GET_ORDER_ID);
      queryClient.invalidateQueries(QUERY_KEY.TREATMENT);
      queryClient.invalidateQueries(QUERY_KEY.GET_ORDER_GROUP);
      toast.success('update created');
    },
    onError: () => {
      onErrorCall();
      toast.error('update To Create');
    },
  });
};

export const useDelete = (onSuccessCall = fn, onErrorCall = fn) => {
  const queryClient = useQueryClient();
  return useMutation(deleteRecord, {
    onSuccess: () => {
      onSuccessCall();
      queryClient.invalidateQueries(QUERY_KEY.GET_ORDER_ID);
      queryClient.invalidateQueries(QUERY_KEY.TREATMENT);
      queryClient.invalidateQueries(QUERY_KEY.GET_ORDER_GROUP);
      toast.success('successfully delete');
    },
    onError: () => {
      onErrorCall();
      toast.error('Unable To delete');
    },
  });
};

export const useGetTreatmentByUser = (filters) => useQuery(['recent', filters],
  getTreatmentByUser, {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: filters?.customerId > 0,
    onError: (e) => {
      console.log(e);
    },
  });
