import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';

const ConfirmationModel = ({
  title, bodyText, visible, handleOk, confirmLoading, handleCancel,
}) => (
  <Modal
    cancelButtonProps={{ disabled: confirmLoading }}
    title={title}
    visible={visible}
    onOk={handleOk}
    confirmLoading={confirmLoading}
    onCancel={handleCancel}
  >
    <p>{bodyText}</p>
  </Modal>
);

ConfirmationModel.propTypes = {
  title: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  confirmLoading: PropTypes.bool.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default ConfirmationModel;
