/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Card, Row } from 'antd';
import Status from './status';

const { Meta } = Card;

const StatusCard = ({
  totalCustomer, total, statusCount, totalBookingPrice,
  totalCancelledPrice, totalProvider,
}) => (
  <Row justify="center" style={{ marginBottom: 10 }} gutter={[16, 8]}>
    <Status counts={totalProvider || 0} label="Providers" labelColor="#1890ff" />
    <Status counts={total || 0} label="Treatments" labelColor="black" />
    {/* <Status counts={totalCustomer || 0} label="Customer" labelColor="#237804" /> */}
    <Status counts={statusCount?.bookCount || 0} label="Booked" labelColor="#135200" isAmount amount={totalBookingPrice} />
    <Status counts={statusCount?.cancelCount || 0} label="Cancelled" labelColor="#d46b08" isAmount amount={totalCancelledPrice} />
  </Row>

);

export default StatusCard;
