/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Input, Select, Row, Col, Button, Form,
} from 'antd';
import { usePromoCodeTypeDropDown } from '_service/promo-code';
import { usePaymentTypeDropDown } from '_service/paymentType';
import { useSelector, useDispatch } from 'react-redux';
import { DatePicker } from '_components/DateControls';
import {
  dateISOFormatStart, dateISOFormatEnd, FORMAT, dateISOFormat, DefaultFormat,
} from '_utils/date-helper';
import { setFilter } from '_store/Treatment';

const { RangePicker } = DatePicker;

const { Search } = Input;
const { Option } = Select;

const Filter = () => {
  const { data: promoCodeType = [] } = usePromoCodeTypeDropDown({ size: 2000 });
  const { data: paymentType = [] } = usePaymentTypeDropDown({ size: 2000 });
  const [dataVisible, setDataVisible] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const filters = useSelector(
    (state) => state.Treatment.filters,
  );
  const setFilterFn = (filter) => dispatch(setFilter(filter));
  return (
    <Form
      layout="horizontal"
      onValuesChange={(key, allFields) => {
        if (key?.filters) { setFilterFn({ ...filters, ...{ ...key.filters, page: 0 } }); }
      }}
      form={form}
    >
      <Row style={{ marginBottom: '16px' }}>
        <Col span={16}>
          <Row gutter={[16, 8]}>
            <Col span={8}>
              <Form.Item name={['filters', 'payment_type_id']} label="">
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Payment Type"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {paymentType.map((item) => (
                    <Option key={item.value}>{item.label}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={['filters', 'promo_code_id']} label="">
                <Select
                  showSearch
                  placeholder="Promo Code"
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {promoCodeType.map((item) => (
                    <Option key={item.value}>{item.label}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col
          span={7}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Form.Item name={['order', 'today']} label="">
            <Select
              defaultValue="today"
              onChange={(value) => {
                if (value === 'Custom') { setDataVisible(true); } else {
                  form.setFieldsValue({
                    order: {
                      Range: null,
                    },
                  });
                  const dates = {
                    start_date: dateISOFormat(new Date()),
                    end_date: dateISOFormat(new Date()),
                  };
                  setFilterFn({ ...filters, ...{ ...dates, page: 0 } });
                  setDataVisible(false);
                }
              }}
              style={{ width: 140, fontWeight: 700, textAlign: 'right' }}
              bordered={false}
            >
              <Option value="today">Today</Option>
              <Option value="Custom">Date Range</Option>
            </Select>
          </Form.Item>

          {dataVisible && (
          <Form.Item
            name={['order', 'Range']}
            label=""
          >
            <RangePicker
              format="DD/MM/YYYY"
              allowClear={false}
              defaultPickerValue={DefaultFormat()}
              onChange={(value) => {
                if (value) {
                  const dates = {
                    start_date: dateISOFormatStart(value[0].$d),
                    end_date: dateISOFormatEnd(value[1].$d),
                  };
                  setFilterFn({ ...filters, ...{ ...dates, page: 0 } });
                }
              }}
            />
          </Form.Item>
          )}
        </Col>
      </Row>
    </Form>
  );
};
export default Filter;
