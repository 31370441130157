/* eslint-disable no-console */
export const getDiscountPrice = (price = 0, discount = null) => {
  if (discount && price) {
    const numVal1 = Number(price);
    const numVal2 = Number(discount) / 100;
    const totalValue = numVal1 - (numVal1 * numVal2);
    return totalValue.toFixed(2);
  }
  return price;
};

export const getVat = (price = 0, vat = null, customerType = false) => {
  if (vat && price && customerType === 1) {
    const numVal1 = Number(price);
    const numVal2 = Number(vat) / 100;
    const totalValue = numVal1 + (numVal1 * numVal2);
    return [totalValue, vat, (numVal1 * numVal2)];
  }
  return [price, 0, 0];
};

export const getPrice = (locationPrice = 0, treatmentNumber = 0, duration = 0) => {
  if (treatmentNumber && duration && locationPrice) {
    const treatmentNumber1 = Number(treatmentNumber);
    const locationPrice1 = Number(locationPrice);
    const duration1 = Number(duration);
    const totalValue = treatmentNumber1 * (duration1 * (Number(locationPrice1)));
    return totalValue.toFixed(2);
  }
  return '';
};

export const getStatus = (st) => {
  switch (st) {
    case 1:
      return 'Pending';
    case 2:
      return 'Confirmed';
    case 4:
      return 'Cancelled';
    default:
      return '-';
  }
};
