/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { useSelector, useDispatch } from 'react-redux';

import { useGet } from '_service/location';
import { isToggleEditFormModelFn, setFilter, isToggleDeleteModelFn } from '_store/Location';

export const useTableView = () => {
  const dispatch = useDispatch();
  const { data, isFetching } = useGet();
  const filters = useSelector(
    (state) => state.Location.filters,
  );
  const selectedRows = useSelector(
    (state) => state.Location.selectedRows,
  );
  const selectedAll = useSelector(
    (state) => state.Location.selectedAll,
  );
  const setFilterFn = (filter) => dispatch(setFilter(filter));
  const isToggleEditFormModel = (values) => dispatch(isToggleEditFormModelFn(values));
  const isToggleDeleteModel = (values) => dispatch(isToggleDeleteModelFn(values));

  return {
    selectedRows,
    filters,
    dataSource: data?.data || [],
    isDataSourceFetching: isFetching,
    total: data?.results || 0,
    selectedAll,
    setFilterFn,
    isToggleEditFormModel,
    isToggleDeleteModel,
  };
};
