/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

const AuthLayout = ({ children }) => (
  <div className="AuthLayoutWrapper">
    {children}
  </div>
);

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

AuthLayout.defaultProps = {
  // bla: 'test',
};

export default AuthLayout;
