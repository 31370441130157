/* eslint-disable no-unused-vars */
import React from 'react';
import { Row, Col, Radio } from 'antd';
import BreadCrumbN from './BreadCrumbN';

const Header = () => (
  <Row>
    <Col style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    }}
    >
      <BreadCrumbN />
    </Col>
  </Row>
);

export default Header;
