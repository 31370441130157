/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React from 'react';
import Table from './Table';
import { useTableView } from './useStateCompose';

const DataTable = () => {
  const {
    filters,
    isDataSourceFetching,
    total,
    dataSource,
  } = useTableView();

  return (
    <>
      <Table
        isDataSourceFetching={isDataSourceFetching}
        total={total}
        dataSource={dataSource}
      />
    </>
  );
};

export default DataTable;
