/* eslint-disable no-unused-vars */
import React from 'react';
import { Row, Col } from 'antd';
import From from './FormEdit';
import AddForm from '../Customer/EditForm';

const AddOrder = () => (
  <>
    <AddForm />
    <Row>
      <Col span={12} offset={6}>
        <From />
      </Col>
    </Row>
  </>

);

export default AddOrder;
