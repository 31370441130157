/* eslint-disable no-unused-vars */
/* eslint-disable no-sequences */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  adjustmentType: null,
  isOpenStatusEditModel: false,
  isOpenEmailModel: false,
  isOpenInvoiceModel: false,
  isOpenAdjustmentModel: false,
  isOpenTabAdjustmentModel: false,
  selectedRows: [],
  selectedAll: false,
  isBulk: false,
  selectedRow: [],
  selectedPartner: {}, // need to verity
  subRouter: {},
  selectedStatus: null,
  currentStatus: null,
  editInvoicesIds: [],
  filters: {
    page: 0,
    size: 10,
    customer_id: null,
    refetch: false,
  },
  tabCount: {},
  IsToggleOrderAddModel: false,
  IsToggleStatusModel: false,
  IsToggleOrderDetailModel: false,
  IsTogglePaymentDetailModel: false,
  IsToggleCustomerEditModel: false,
  ///
};
const OrderReducer = createSlice({
  name: 'orderListGroup',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      const filter = { ...action.payload, ...{ refetch: !state?.filters.refetch } };
      state.filters = filter;
      state.selectedRows = [];
      state.selectedAll = false;
    },
    setSelectedRows: (state, action) => {
      const { selectedRows = [], selectedAll = false } = action.payload || {};
      state.selectedRows = selectedRows;
      state.selectedAll = selectedAll;
    },
    reset: (state) => initialState,
  },
});

export const {
  isTogglePaymentDetailModel,
  isToggleCustomerEditModel,
  isToggleStatusModel,
  isToggleOrderDetailModel,
  setFilter,
  setSelectedRows,
  reset,
} = OrderReducer.actions;

export default OrderReducer.reducer;
