/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import {
  List, Avatar, Tag,
} from 'antd';
import VirtualList from 'rc-virtual-list';
import { isSameDay, dateFormat, FORMAT } from '_utils/date-helper';

const DataTable = ({
  dataSource,
}) => (
  <div>
    <List>
      <VirtualList
        data={dataSource}
        height={300}
        itemHeight={47}
        itemKey="email"
      >
        {(item) => (
          <List.Item key={item.id}>
            <List.Item.Meta
              avatar={(
                <Avatar style={{
                  backgroundColor: '#e98476',
                  verticalAlign: 'middle',
                }}
                >
                  {item.fullName[0]}
                </Avatar>
)}
              title={item.fullName}
              description={item.title}
            />
            <Tag color="geekblue">{dateFormat(item.paymentEndDate, FORMAT.MMMDD)}</Tag>
          </List.Item>
        )}
      </VirtualList>
    </List>
  </div>
);

export default DataTable;
