/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Form,
  Input,
  Layout,
  Row,
  Col,
  Typography,
} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import logoPaw from '_assets/images/cheetay-logo-paw.svg';
import ButtonAtn from '_components/ButtonAtn';
import logoPaws4 from '_assets/images/Asset5.svg';
import './login.css';
import { useLogin } from '_service/user';

const { Content } = Layout;
const { Title } = Typography;

const Login = () => {
  const { isLoading, mutate } = useLogin();
  // const { Link } = Typography;
  const onFinish = (payload) => {
    const params = {
      ...payload.user,
    };
    mutate(params);
  };
  return (
    <>
      <Layout className="loginbg">
        <Content>
          <Row justify="center" align="middle" className="login-wrapper">
            <Col xs={20} sm={16} md={12} lg={8} xl={8} xxl={6}>
              <div className="login-container" style={{ borderRadius: 24 }}>
                <div className="logo-paw">
                  <img src={logoPaws4} width={64} height={64} alt="" />
                </div>
                <Title level={4} style={{ marginBottom: 2 }}>Sign in</Title>
                {/* <Title level={5} style={{ marginTop: 1, marginBottom: 20 }}>Cash Collection</Title> */}
                <Form
                  name="normal_login"
                  className="login-form"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                >
                  <Form.Item
                    name={['user', 'email']}
                    rules={[
                      {
                        required: true,
                        message: 'Please input your email!',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                  </Form.Item>
                  <Form.Item
                    name={['user', 'password']}
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Password!',
                      },
                    ]}
                  >
                    <Input
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      placeholder="Password"
                    />
                  </Form.Item>
                  <Form.Item>
                    <ButtonAtn loading={isLoading} style={{ width: '90%' }} text="login" onclick={() => { }} htmlType="submit" className="login-form-button" />
                  </Form.Item>
                </Form>
              </div>
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};

export default Login;
