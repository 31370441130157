/* eslint-disable no-unused-vars */
import * as React from 'react';
import { PickerTimeProps } from 'antd/es/date-picker/generatePicker';
import DatePicker from './DatePicker';

const TimePicker = React.forwardRef((props, ref) => <DatePicker {...props} picker="time" mode={undefined} ref={ref} />);

TimePicker.displayName = 'TimePicker';

export default TimePicker;
