import React from 'react';
import { Breadcrumb, Typography } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';

const { Text } = Typography;

const BreadCrumbN = () => {
  const { url } = useRouteMatch();
  return (
    <Breadcrumb>
      <Breadcrumb.Item key={`${url}`}>
        <Link to="/order/create">Create Order</Link>
      </Breadcrumb.Item>
      { url.includes('/order/create/details') && (
      <Breadcrumb.Item key={`${url}/details/:id`}>
        <Text>
          Order Detail
        </Text>
      </Breadcrumb.Item>
      )}
    </Breadcrumb>
  );
};

export default BreadCrumbN;
