/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Input, Select, Row, Col, Button, Form,
} from 'antd';
import { usePromoCodeTypeDropDown } from '_service/promo-code';
import { usePaymentTypeDropDown } from '_service/paymentType';
import { useSelector, useDispatch } from 'react-redux';
import { DatePicker } from '_components/DateControls';
import {
  dateISOFormat, FORMAT, dateISOFormatStart, dateISOFormatEnd, dateFormat,
} from '_utils/date-helper';
import DebounceSelect from '_components/Search';
import { SearchByName as CustomerSearch } from '_api/customer';
import { setFilter } from '_store/order';

const { RangePicker } = DatePicker;

const { Search } = Input;
const { Option } = Select;
const inFilters = {
  order_number: null,
  promo_code_id: null,
  payment_type_id: null,
  status_id: null,
  start_date: null,
  end_date: null,
  page: 0,
  size: 10,
  customer_id: null,
  refetch: false,
};
const Filter = () => {
  const { data: promoCodeType = [] } = usePromoCodeTypeDropDown({ size: 1000 });
  const { data: paymentType = [] } = usePaymentTypeDropDown({ size: 1000 });
  const [dataVisible, setDataVisible] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const filters = useSelector(
    (state) => state.Order.filters,
  );
  const setFilterFn = (filter) => dispatch(setFilter(filter));
  return (
    <Form
      layout="horizontal"
      onValuesChange={(key, allFields) => {
        if (key?.filters) { setFilterFn({ ...filters, ...{ ...key.filters, page: 0 } }); }
      }}
      form={form}
    >
      <Row style={{ marginBottom: '16px' }} gutter={[16, 8]}>
        <Col span={4}>
          <Form.Item name={['order', 'order_number']} label="">
            <Search
              allowClear
              placeholder="search order number"
              enterButton
              onSearch={(value) => {
                setFilterFn({ ...filters, ...{ order_number: value, page: 0 } });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={['orders', 'customer_id']} label="">
            <DebounceSelect
              showSearch
              allowClear
              placeholder="search customer"
              fetchOptions={CustomerSearch}
              onChange={(newValue) => {
                setFilterFn({ ...filters, ...{ customer_id: newValue ? newValue.value : null, page: 0 } });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={['filters', 'payment_type_id']} label="">
            <Select
              allowClear
              showSearch
              placeholder="Select Payment Type"
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {paymentType.map((item) => (
                <Option key={item.value}>{item.label}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={['filters', 'promo_code_id']} label="">
            <Select
              showSearch
              placeholder="Promo Code"
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {promoCodeType.map((item) => (
                <Option key={item.value}>{item.label}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col
          span={8}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Form.Item name={['order', 'today']} label="">
            <Select
              defaultValue="recent"
              onChange={(value) => {
                if (value === 'recent') {
                  setDataVisible(false);
                  form.setFieldsValue({
                    order: {
                      Range: null,
                    },
                  });
                  const dates = {
                    start_date: null,
                    end_date: null,
                  };
                  setFilterFn({ ...filters, ...{ ...dates, range: 10, page: 0 } });
                  return;
                }

                if (value === 'Custom') {
                  setDataVisible(true);
                } else {
                  form.setFieldsValue({
                    order: {
                      Range: null,
                    },
                  });
                  const dates = {
                    start_date: dateFormat(new Date(), FORMAT.YYYYMMDD),
                    end_date: dateFormat(new Date(), FORMAT.YYYYMMDD),
                  };
                  setFilterFn({ ...filters, ...{ ...dates, range: null, page: 0 } });
                  setDataVisible(false);
                }
              }}
              style={{ width: 140, fontWeight: 700, textAlign: 'right' }}
              bordered={false}
            >
              <Option value="recent">Recent Orders</Option>
              <Option value="today">Today</Option>
              <Option value="Custom">Date Range</Option>
            </Select>
          </Form.Item>
          {dataVisible && (
          <Form.Item
            name={['order', 'Range']}
            label=""
          >
            <RangePicker
              format="DD/MM/YYYY"
              allowClear={false}
              onChange={(value) => {
                if (value) {
                  const dates = {
                    start_date: dateFormat(value[0].$d, FORMAT.YYYYMMDD),
                    end_date: dateFormat(value[1].$d, FORMAT.YYYYMMDD),
                  };
                  setFilterFn({ ...filters, ...{ ...dates, range: null, page: 0 } });
                }
              }}
            />
          </Form.Item>
          )}
        </Col>
      </Row>
    </Form>
  );
};
export default Filter;
