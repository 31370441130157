/* eslint-disable no-sequences */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getStartOfMonth, getEndOfMonth } from '_utils/date-helper';

const initialState = {
  isOpenAddCycleModel: false,
  isConfirmationModel: false,
  selectedCycle: {},
  subRouter: {},
  filters: {
    // startDate: `${getStartOfMonth().format(FORMAT.YYYYMMDD)}T00:00:00Z`,
    // endDate: `${getEndOfMonth().format(FORMAT.YYYYMMDD)}T00:00:00Z`,
    start_date: getStartOfMonth(),
    end_date: getEndOfMonth(),
    vendor_type: '1P',
    time: false,
  },
  confirmation: {
    title: '',
    bodyText: '',
  },
};
const PartnerInvoiceReducer = createSlice({
  name: 'PartnerInvoiceReducer',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      const filter = { ...action.payload, ...{ time: !state.filters.time } };
      state.filters = filter;
    },
    toggleAddCycleModel: (state) => {
      state.isOpenAddCycleModel = !state.isOpenAddCycleModel;
    },
    toggleConfirmationModel: (state, action) => {
      state.isConfirmationModel = !state.isConfirmationModel;
      state.confirmation = action?.payload || initialState.confirmation;
    },
    selectInvoiceCycle: (state, action) => {
      state.selectedCycle = action.payload || {};
    },
    reset: () => initialState,
  },
});
export const {
  toggleAddCycleModel,
  reset,
  toggleConfirmationModel,
  selectInvoiceCycle,
  setFilter,
} = PartnerInvoiceReducer.actions;

export default PartnerInvoiceReducer.reducer;
