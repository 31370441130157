/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Form as FormAnt,
  Select,
  Input,
  InputNumber,
  Button,
} from 'antd';
import { Search } from '_api/customer';
import DebounceSelect from '_components/SearchInput';

import PropTypes from 'prop-types';

const { Option } = Select;
const Form = ({
  form, onFinish, selectedRow, isToggleFormModel, treatmentType, location,
}) => {
  const [fields, setFields] = useState();
  const [treatment, setTreatment] = React.useState([]);
  useEffect(() => {
    setFields([
      {
        name: ['order', 'customer'],
        value: { label: selectedRow?.customer?.fullName, value: selectedRow.customerId } || {},
      },
      {
        name: ['order', 'email'],
        value: selectedRow?.customer?.email || '',
      },
      {
        name: 'customerId',
        value: selectedRow?.customerId || '',
      },
      {
        name: ['order', 'phoneNumber'],
        value: selectedRow?.customer?.phoneNumber || '',
      },
      {
        name: ['order', 'billingAddress'],
        value: selectedRow?.customer?.billingAddress || '',
      },
    ]);
  }, [isToggleFormModel]);

  return (
    <FormAnt
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 14,
      }}
      layout="horizontal"
      form={form}
      name="Change-Status"
      onFinish={onFinish}
      fields={fields}
    >
      <FormAnt.Item noStyle label="Customer" name="customerId" />
      <FormAnt.Item label="Customer" name={['order', 'customer']} rules={[{ required: true, message: 'Required!' }]}>
        <DebounceSelect
          showSearch
          allowClear
          placeholder="search Customer"
          fetchOptions={Search}
          style={{ width: 200 }}
          onChange={(newValue) => {
            const { value: values = '{"email":"", "billingAddress":"" , "phoneNumber":""}' } = newValue || {};
            const {
              id, billingAddress = '', email = '', phoneNumber, fullName,
            } = JSON.parse(values) || {};
            form.setFieldsValue({
              customerId: id,
              order: {
                billingAddress,
                email,
                phoneNumber,
              },
            });
          }}
        />
      </FormAnt.Item>
      <FormAnt.Item name={['order', 'email']} label="Email" rules={[{ type: 'email' }]}>
        <Input disabled />
      </FormAnt.Item>
      <FormAnt.Item
        label="Phone Number"
        name={['order', 'phoneNumber']}
      >
        <Input disabled style={{ width: '100%' }} />
      </FormAnt.Item>
      <FormAnt.Item name={['order', 'billingAddress']} label="Billing Address">
        <Input.TextArea disabled />
      </FormAnt.Item>
    </FormAnt>
  );
};

Form.propTypes = {
  form: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
  selectedRow: PropTypes.object.isRequired,
};

export default Form;
