/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Dropdown } from 'antd';

const DropdownMenu = ({
  trigger, onMenuClick, options, text,
}) => (
  <Dropdown.Button
    trigger={trigger}
    overlay={() =>
      // const disableList = getDisableList();
      (
        <Menu onClick={onMenuClick}>
          {
            options.map((item) => (
              <Menu.Item key={item.key}>
                {item.title}
              </Menu.Item>
            ))
          }
        </Menu>
      )}
  >
    {text}
  </Dropdown.Button>
);

DropdownMenu.propTypes = {
  options: PropTypes.array.isRequired,
  onMenuClick: PropTypes.func.isRequired,
  trigger: PropTypes.array,
  text: PropTypes.string,
};

DropdownMenu.defaultProps = {
  text: '',
  trigger: ['click'],
};

export default DropdownMenu;
