import React from 'react';
import {
  Layout,
} from 'antd';

import OrderListView from './OrderListView';

const PartnersInvoice = () => (
  <Layout style={{ padding: '16px 24px' }}>
    <OrderListView />
  </Layout>
);

export default PartnersInvoice;
