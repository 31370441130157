/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PATH } from 'utilities/common';
import {
  Layout, Menu,
} from 'antd';
import {
  FileOutlined,
  TeamOutlined,
  ContainerOutlined,
} from '@ant-design/icons';
import {
  FaMapMarker, FaPeopleArrows, FaCalendarAlt, FaFileMedicalAlt,
} from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import logoPaws4 from '_assets/images/Asset5.svg';

const { Sider } = Layout;
const { SubMenu } = Menu;
const SideBar = ({ collapsed, onToggleCollapse }) => {
  const location = useLocation();
  return (
    <Sider
      style={{
        overflowY: 'auto',
        height: '100vh',
        background: 'black',
      }}
      trigger={null}
      collapsible
      collapsed={collapsed}
      breakpoint="lg"
      collapsedWidth="50"
      onBreakpoint={(broken) => {
        if (broken) {
          onToggleCollapse(true);
        }
      }}
      onCollapse={(collapse, type) => {
      }}
    >
      <div style={!collapsed ? { padding: 20, paddingLeft: 24 } : { padding: '10px calc(50% - 32px / 2)' }}>
        {!collapsed ? <img src={logoPaws4} width={64} height={64} alt="" /> : <img src={logoPaws4} width={32} height={32} alt="" /> }
      </div>
      <Menu mode="inline" theme="dark" defaultSelectedKeys={[location?.pathname]} style={{ background: 'black' }}>
        <Menu.Item key="/dashboard" icon={<ContainerOutlined />}>
          Dashboard
          <Link to="/dashboard" />
        </Menu.Item>
        <SubMenu key="sub1" icon={<ContainerOutlined />} title="Orders">
          <Menu.Item key="/orders-groups">
            Customer Treatment
            <Link to="/orders-groups" />
          </Menu.Item>
          <Menu.Item key="/orders">
            Orders list
            <Link to="/orders" />
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="/treatment" icon={<FaCalendarAlt />}>
          Treatment
          <Link to="/treatment" />
        </Menu.Item>
        <Menu.Item key="/promo-code" icon={<FileOutlined />}>
          Promo Code
          <Link to="/promo-code" />
        </Menu.Item>
        <Menu.Item key="/treatment-type" icon={<FaFileMedicalAlt />}>
          Treatment Type
          <Link to="/treatment-type" />
        </Menu.Item>
        <Menu.Item key="/customer" icon={<TeamOutlined />}>
          Customer
          <Link to="/customer" />
        </Menu.Item>
        <Menu.Item key="/location" icon={<FaMapMarker />}>
          Location
          <Link to="/location" />
        </Menu.Item>
        <Menu.Item key="/provider" icon={<FaPeopleArrows />}>
          Provider
          <Link to="/provider" />
        </Menu.Item>
        <Menu.Item key="/extract" icon={<FaCalendarAlt />}>
          Extract
          <Link to="/extract" />
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

SideBar.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  onToggleCollapse: PropTypes.func.isRequired,
};

export default SideBar;
