import React from 'react';
import {
  Layout,
} from 'antd';

import './PartnersInvoice.css';
import SubRoutes from './SubRoutes';

const PartnersInvoice = () => (
  <Layout style={{ padding: '16px 24px' }}>
    <SubRoutes />
  </Layout>
);

export default PartnersInvoice;
