/* eslint-disable max-len */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import {
  Form,
} from 'antd';
import Dialog from '_components/Dialog';
import { useCreate } from '_service/treatment';
import { dateISOFormat } from '_utils/date-helper';
import { useStateCompose } from './useStateCompose';
import TabAdjustmentForm from './Form';

const AddForm = ({ data, onSave = () => {} }) => {
  const [form] = Form.useForm();
  const {
    selectedRow, isToggleFormModel, toggleFormModelFn,
  } = useStateCompose();
  const onError = () => {
    toggleFormModelFn();
    form.resetFields();
  };
  const OnSuccess = () => {
    toggleFormModelFn();
    onSave();
    form.resetFields();
  };
  const { mutate, isLoading } = useCreate(OnSuccess, onError);
  const onOk = () => {
    form.submit();
  };
  const onFinish = (values) => {
    const {
      treatment = {}, providers, order, treatmentTypeList = {},
    } = values;
    const payload = {
      ...values.treatment,
      treatmentDatetime: dateISOFormat(treatment?.treatmentDatetime.$d),
      providerId: providers?.providerId?.value,
      treatmentTypeId: treatmentTypeList?.treatmentTypeId?.value
        ? treatmentTypeList?.treatmentTypeId?.value : treatmentTypeList?.treatmentTypeId,
      active: true,
    };
    mutate(payload);
  };
  return (
    <>
      {isToggleFormModel && (
      <Dialog
        cancelButtonProps={{ disabled: isLoading }}
        title="Add Treatment"
        confirmLoading={isLoading}
        visible={isToggleFormModel}
        onOk={onOk}
        okText="Save"
        onCancel={() => { form.resetFields(); toggleFormModelFn(); }}
        afterClose={() => { form.resetFields(); }}
      >
        <TabAdjustmentForm
          form={form}
          data={data}
          onFinish={onFinish}
          selectedRow={selectedRow}
          isToggleFormModel={isToggleFormModel}
        />
      </Dialog>
      )}
    </>
  );
};

export default AddForm;
