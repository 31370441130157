import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useCallback } from 'react';
import { QUERY_KEY } from '_service/Alias';
import {
  get, create, update, deleteRecord,
} from '_api/promo-code';
import { useSelector, useDispatch } from 'react-redux';
import {
  setFilter,
  setSelectedRows,
} from '_store/promoCode';
import { message as toast } from 'antd';

const fn = () => {};
export const usePromoCodeType = (select) => {
  const dispatch = useDispatch();
  const { filters } = useSelector(
    (state) => state.PromoCode,
  );
  return useQuery([QUERY_KEY.GET_PROMO_CODE, filters],
    get, {
      retry: false,
      select,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        dispatch(setSelectedRows({}));
      },
      onError: () => {
        if (filters.page > 1) {
          const updateFilter = { ...filters, ...{ page: 1 } };
          dispatch(setFilter(updateFilter));
        }
      },
    });
};

export const usePromoCodeTypeDropDown = (params) => useQuery([QUERY_KEY.PROMO_CODE_DROPDOWN,
  params],
get, {
  select: useCallback(
    ({ data = {} }) => data.map((ele) => ({
      label: `${ele.title}`,
      value: ele.id,
    })),
    [],
  ),
});

export const useCreate = (onSuccessCall = fn, onErrorCall = fn) => {
  const queryClient = useQueryClient();
  return useMutation(create, {
    onSuccess: () => {
      onSuccessCall();
      queryClient.invalidateQueries(QUERY_KEY.GET_PROMO_CODE);
      toast.success('successfully created Promo Code');
    },
    onError: () => {
      onErrorCall();
      toast.error('Unable To Create Promo Code');
    },
  });
};
export const useUpdate = (onSuccessCall = fn, onErrorCall = fn) => {
  const queryClient = useQueryClient();
  return useMutation(update, {
    onSuccess: () => {
      onSuccessCall();
      queryClient.invalidateQueries(QUERY_KEY.GET_PROMO_CODE);
      toast.success('update created Promo Code');
    },
    onError: () => {
      onErrorCall();
      toast.error('update To Create Promo Code');
    },
  });
};

export const useDelete = (onSuccessCall = fn, onErrorCall = fn) => {
  const queryClient = useQueryClient();
  return useMutation(deleteRecord, {
    onSuccess: () => {
      onSuccessCall();
      queryClient.invalidateQueries(QUERY_KEY.GET_PROMO_CODE);
      toast.success('successfully delete');
    },
    onError: () => {
      onErrorCall();
      toast.error('Unable To delete');
    },
  });
};
