/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Form as FormAnt,
  Input,
} from 'antd';
import PropTypes from 'prop-types';
import { DatePicker } from '_components/DateControls';

const { RangePicker } = DatePicker;
const Form = ({
  form, onFinish,
}) => (
  <FormAnt
    labelCol={{
      span: 6,
    }}
    wrapperCol={{
      span: 14,
    }}
    layout="horizontal"
    form={form}
    name="Promo Code"
    onFinish={onFinish}
    spellCheck={false}
  >
    <FormAnt.Item
      name={['promo', 'title']}
      rules={[{ required: true, message: 'Title required!' }]}
      whitespace={false}
      label="Title"
    >
      <Input />
    </FormAnt.Item>
    <FormAnt.Item
      name={['promo', 'code']}
      rules={[{ required: true, message: 'Code required!' }]}
      whitespace={false}
      label="Code"
    >
      <Input />
    </FormAnt.Item>
    <FormAnt.Item
      name={['promo', 'discountpre']}
      rules={[{ required: true, message: 'Discount required!' }, () => ({
        validator(_, value) {
          if (value != null && value <= 0) {
            return Promise.reject(new Error('Value must be grater then zero'));
          }
          if (value > 100) {
            return Promise.reject(new Error('Value must be less than 100'));
          }
          return Promise.resolve();
        },
      })]}
      whitespace={false}
      label="Discount %"
    >
      <Input type="number" />
    </FormAnt.Item>
    <FormAnt.Item
      name={['RangePicker', 'Range']}
      label="Active Range"
      rules={[{ required: true, message: 'RangePicker required!' }]}
      whitespace={false}
    >
      <RangePicker
        format="DD/MM/YYYY"
      />
    </FormAnt.Item>
    <FormAnt.Item
      name={['promo', 'details']}
      label="Details"
    >
      <Input.TextArea />
    </FormAnt.Item>
  </FormAnt>
);

Form.propTypes = {
  form: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default Form;
