/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { useSelector, useDispatch } from 'react-redux';

import { useGetByOrderId } from '_service/treatment';
import {
  isToggleEditFormModelFn,
  setFilter,
  isToggleDeleteModelFn,
  isToggleFormModelFn,
  setFilterOrder,
} from '_store/Treatment';

export const useTableView = (orderId) => {
  const dispatch = useDispatch();
  const { data, isFetching } = useGetByOrderId(orderId);
  const filters = useSelector(
    (state) => state.Treatment.filters,
  );
  const selectedRows = useSelector(
    (state) => state.Treatment.selectedRows,
  );
  const selectedAll = useSelector(
    (state) => state.Treatment.selectedAll,
  );
  const setFilterFn = (filter) => dispatch(setFilterOrder(filter));
  const isToggleEditFormModel = (values) => dispatch(isToggleEditFormModelFn(values));
  const isToggleDeleteModel = (values) => dispatch(isToggleDeleteModelFn(values));
  const isToggleFormModelFnAdd = (values) => dispatch(isToggleFormModelFn(values));
  return {
    selectedRows,
    filters,
    dataSource: data?.data || [],
    isDataSourceFetching: isFetching,
    total: data?.results || 0,
    selectedAll,
    setFilterFn,
    isToggleEditFormModel,
    isToggleDeleteModel,
    isToggleFormModelFnAdd,
  };
};
