/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import {
  Row, Descriptions, Button, Col,
} from 'antd';
import Table from 'pages/Treatment/ListView/Table';
import AddForm from 'pages/Treatment/AddForm';
import EditForm from 'pages/Treatment/EditForm';
import Confirmation from 'pages/Treatment/Confirmation';
import { useTableView } from './useStateCompose';

const DataTable = ({ data = {} }) => {
  const {
    filters,
    isDataSourceFetching,
    total,
    dataSource,
    setFilterFn,
    isToggleEditFormModel,
    isToggleDeleteModel,
    isToggleFormModelFnAdd,
  } = useTableView(data?.id);
  return (
    <>
      <Confirmation />
      <AddForm data={data} />
      <EditForm />
      <Descriptions
        title={<h4 style={{ margin: '10px' }}>Treatment</h4>}
        style={{
          backgroundColor: '#ffffff',
          marginTop: 20,
        }}
        labelStyle={{ fontWeight: 600, backgroundColor: '#ffffff' }}
        contentStyle={{ backgroundColor: '#ffffff !important' }}
        bordered
        // extra={<Button type="primary" onClick={() => { isToggleFormModelFnAdd(); }}>Add</Button>}
        column={{
          xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1,
        }}
      >
        <div>
          <Table
            filters={filters}
            isDataSourceFetching={isDataSourceFetching}
            total={total}
            dataSource={dataSource}
            setFilterFn={setFilterFn}
            isToggleEditFormModel={isToggleEditFormModel}
            isToggleDeleteModel={isToggleDeleteModel}
          />
        </div>
      </Descriptions>
    </>
  );
};

export default DataTable;
