/* eslint-disable no-undef */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Layout, Dropdown, Avatar, Typography, Menu as MenuAnt,
} from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from '_store/order';
import { setLoginSuccess } from '_store/user';
import SideBar from './SideBar';
import './index.css';

const { Header, Content } = Layout;
const { Text } = Typography;
const Menu = ({ onMenuClick }) => (
  <MenuAnt onClick={onMenuClick}>
    <MenuAnt.Item key="Log_out">
      Logout
    </MenuAnt.Item>
  </MenuAnt>
);

const DashboardLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const user = useSelector(
    (state) => state.user.user || {},
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const onMenuClick = (e) => {
    if (e.key === 'Log_out') {
      history.replace('/');
      dispatch(reset());
      dispatch(setLoginSuccess(null));
    }
  };
  return (
    <Layout>
      <SideBar
        collapsed={collapsed}
        onToggleCollapse={(value) => setCollapsed(value)}
      />
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          {React.createElement(
            (!!collapsed) ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: 'trigger',
              onClick: () => {
                setCollapsed(!collapsed);
              },
            },
          )}
          <div style={{ float: 'right', marginRight: 5 }}>
            <Text style={{
              padding: 0, margin: 0, marginRight: 5, userSelect: 'none',
            }}
            >
              {user?.name || ''}
            </Text>
            <Dropdown overlay={<Menu onMenuClick={(e) => onMenuClick(e)} />} placement="bottomRight">
              <Avatar style={{ backgroundColor: '#ff4f00' }} icon={<UserOutlined />} />
            </Dropdown>
          </div>
        </Header>
        <Content style={{ maxHeight: 'calc(100vh - 64px)', overflowY: 'auto' }}>
          {children}
        </Content>
      </Layout>

    </Layout>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
