import React from 'react';
import {
  Layout,
} from 'antd';
import MainBoard from './MainBoard';

const Dashboard = () => (
  <Layout style={{ padding: '16px 24px' }}>
    <MainBoard />
  </Layout>
);

export default Dashboard;
