/* eslint-disable no-return-await */
/* eslint-disable no-unused-vars */
import { apiCaller } from '../middleware/api-caller';
import { ENDPOINTS, REQUEST_TYPES } from '../middleware/url';

export const getOrder = async ({ queryKey }) => {
  try {
    const [_key, params] = queryKey;
    const data = { ...params };
    if (data.refetch) {
      delete data.refetch;
    }
    const response = await apiCaller({
      method: REQUEST_TYPES.GET,
      url: `${ENDPOINTS.TREATMENT_CUSTOMER_GROUP}`,
      Auth: true,
      params: {
        ...data,
      },
    });
    if (!response) {
      throw new Error('Network response was not ok');
    }
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
