import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
// import { ReactQueryDevtools } from 'react-query/devtools';
import store from './_store';
import AppRouter from './AppRouter';
import 'react-toastify/dist/ReactToastify.css';
import './App.less';

const queryClient = new QueryClient();
const persistor = persistStore(store);

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          {/* <ReactQueryDevtools initialIsOpen /> */}
          <AppRouter />
        </QueryClientProvider>
      </PersistGate>
      <ToastContainer />
    </Provider>
  );
}

export default App;
