/* eslint-disable no-unused-vars */
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { QUERY_KEY } from '_service/Alias';
import { useSelector, useDispatch } from 'react-redux';
import {
  setFilter,
  setSelectedRows,
} from '_store/order';
import { message as toast } from 'antd';
import {
  createOrder,
  getOrder,
  getOrderById,
  update,
  getChartJS,
  getOrderByUser,
} from '_api/order';

const fn = () => {};
export const useGetOrder = (select) => {
  const dispatch = useDispatch();
  const { filters } = useSelector(
    (state) => state.Order,
  );
  return useQuery([QUERY_KEY.GET_ORDER, filters],
    getOrder, {
      retry: false,
      select,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        dispatch(setSelectedRows({}));
      },
      onError: () => {
        if (filters.page > 1) {
          const updateFilter = { ...filters, ...{ page: 1 } };
          dispatch(setFilter(updateFilter));
        }
      },
    });
};

export const useGetOrderById = (filters) => useQuery([QUERY_KEY.GET_ORDER_ID, filters],
  getOrderById, {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: filters?.id > 0,
    onError: () => {
      toast.error('Unable get details');
    },
  });

export const useGetOrderByUser = (filters) => useQuery([QUERY_KEY.GET_ORDER_ID_USER, filters],
  getOrderByUser, {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: filters?.customerId > 0,
  });

export const useCreateOrder = (
  onSuccessCall = fn, onErrorCall = fn,
) => useMutation(createOrder, {
  onSuccess: (res) => {
    const { data = {} } = res || {};
    onSuccessCall(data);
    toast.success('Order created successfully');
  },
  onError: () => {
    onErrorCall();
    toast.error('Unable to create order');
  },
});

export const useUpdate = (onSuccessCall = fn, onErrorCall = fn) => {
  const queryClient = useQueryClient();
  return useMutation(update, {
    onSuccess: () => {
      onSuccessCall();
      queryClient.invalidateQueries(QUERY_KEY.GET_ORDER);
      queryClient.invalidateQueries(QUERY_KEY.GET_ORDER_ID);
      toast.success('update successfully');
    },
    onError: () => {
      onErrorCall();
      toast.error('Unable update');
    },
  });
};
const labels = ['January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'];

const getMonthNumber = (YearStats = {}) => {
  const prev = [];
  labels.forEach((ele) => {
    const { orders = null } = YearStats.find((x) => x.orderMonth.trim() === ele) || {};
    if (orders) {
      prev.push(orders);
    } else {
      prev.push(0);
    }
  });
  return prev;
};
const getMonthNumberLines = (YearStats = {}) => {
  const prev = [];
  labels.forEach((ele) => {
    const { totalAmount = null } = YearStats.find((x) => x.orderMonth.trim() === ele) || {};
    if (totalAmount) {
      prev.push(totalAmount);
    } else {
      prev.push(0);
    }
  });
  return prev;
};
const transformTodoNames = (data) => {
  const currentTime = new Date();
  const { previousYearStats, currentYearStats } = data || {};
  return {
    labelCurrent: currentTime.getFullYear(),
    labelPrev: currentTime.getFullYear() - 1,
    Current: getMonthNumber(currentYearStats),
    Prev: getMonthNumber(previousYearStats),
    labelCurrentLine: currentTime.getFullYear(),
    labelPrevLine: currentTime.getFullYear() - 1,
    CurrentLine: getMonthNumberLines(currentYearStats),
    PrevLine: getMonthNumberLines(previousYearStats),
  };
};

export const useGetOrderChartJs = () => useQuery([QUERY_KEY.GET_ORDER_ID_JS],
  getChartJS, {
    retry: false,
    refetchOnWindowFocus: false,
    select: transformTodoNames,
    onError: () => {
      toast.error('Unable get details');
    },
  });
