/* eslint-disable no-unused-vars */
/* eslint-disable no-sequences */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isToggleFormModel: false,
  isToggleEditFormModel: false,
  isToggleDeleteModel: false,
  confirmationContent: {},
  selectedRow: {},
  filters: {
    search: null,
    page: 0,
    size: 10,
    refetch: false,
  },
};
const CustomerReducer = createSlice({
  name: 'Customer',
  initialState,
  reducers: {
    isToggleFormModelFn: (state) => {
      state.isToggleFormModel = !state.isToggleFormModel;
    },
    setFilter: (state, action) => {
      const filter = { ...action.payload, ...{ refetch: !state?.refetch } };
      state.filters = filter;
      state.selectedRow = {};
    },
    setSelectedRows: (state, action) => {
      const { selectedRow = {} } = action.payload || {};
      state.selectedRow = selectedRow;
    },
    isToggleEditFormModelFn: (state, action) => {
      const { selectedRow = {} } = action.payload || {};
      state.isToggleEditFormModel = !state.isToggleEditFormModel;
      state.selectedRow = selectedRow;
    },
    isToggleDeleteModelFn: (state, action) => {
      const { selectedRow = {} } = action.payload || {};
      state.isToggleDeleteModel = !state.isToggleDeleteModel;
      state.selectedRow = selectedRow;
      state.confirmationContent = { title: 'Delete ', bodyText: 'Are you sure to delete this record ?' };
    },
    reset: (state) => initialState,
  },
});

export const {
  isToggleFormModelFn,
  setFilter,
  setSelectedRows,
  reset,
  isToggleEditFormModelFn,
  isToggleDeleteModelFn,
} = CustomerReducer.actions;

export default CustomerReducer.reducer;
