import { isToggleFormModelFn } from '_store/Customer';
import { useSelector, useDispatch } from 'react-redux';

export const useStateCompose = () => {
  const dispatch = useDispatch();
  const isToggleFormModel = useSelector(
    (state) => state.Customer.isToggleFormModel,
  );
  const selectedRow = useSelector(
    (state) => state.Customer.selectedRow,
  );
  const toggleFormModelFn = () => dispatch(isToggleFormModelFn());
  return { selectedRow, isToggleFormModel, toggleFormModelFn };
};
