/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Form as FormAnt,
  Input,
} from 'antd';
import PropTypes from 'prop-types';

const Form = ({
  form, onFinish, selectedRow, isToggleFormModel,
}) => {
  const [fields, setFields] = useState();
  useEffect(() => {
    setFields([
      {
        name: ['TreatmentType', 'title'],
        value: selectedRow?.title || null,
      },
      {
        name: ['TreatmentType', 'ownLocationPrice'],
        value: selectedRow?.ownLocationPrice || null,
      },
      {
        name: ['TreatmentType', 'clientLocationPrice'],
        value: selectedRow?.clientLocationPrice || null,
      },
      {
        name: ['TreatmentType', 'vatRate'],
        value: selectedRow?.vatRate || 0,
      },
    ]);
  }, [isToggleFormModel]);
  return (
    <FormAnt
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 14,
      }}
      layout="horizontal"
      form={form}
      name="Edit Customer"
      onFinish={onFinish}
      fields={fields}
    >
      <FormAnt.Item
        name={['TreatmentType', 'title']}
        rules={[{ required: true, message: 'Title required!' }]}
        whitespace={false}
        label="Title"
      >
        <Input />
      </FormAnt.Item>
      <FormAnt.Item
        name={['TreatmentType', 'ownLocationPrice']}
        rules={[{ required: true, message: 'Please input the price' },
          () => ({
            validator(_, value) {
              if (value != null && value <= 0) {
                return Promise.reject(new Error('Value must be grater then zero'));
              }
              return Promise.resolve();
            },
          }),
        ]}
        whitespace={false}
        label="Own Location Price"
      >
        <Input suffix="SEK" />
      </FormAnt.Item>
      <FormAnt.Item
        name={['TreatmentType', 'clientLocationPrice']}
        whitespace={false}
        rules={[{ required: true, message: 'Please input the price' },
          () => ({
            validator(_, value) {
              if (value != null && value <= 0) {
                return Promise.reject(new Error('Value must be grater then zero'));
              }
              return Promise.resolve();
            },
          }),
        ]}
        label="Client Location Price"
      >
        <Input suffix="SEK" />
      </FormAnt.Item>
      <FormAnt.Item
        name={['TreatmentType', 'vatRate']}
        rules={[{ required: true, message: 'VatRate required!' }, () => ({
          validator(_, value) {
            if (value == 0) {
              return Promise.resolve();
            }
            if (value != null && value <= 0) {
              return Promise.reject(new Error('Value must be grater then zero'));
            }
            if (value > 100) {
              return Promise.reject(new Error('Value must be less than 100'));
            }
            return Promise.resolve();
          },
        })]}
        whitespace={false}
        label="VAT %"
      >
        <Input type="number" />
      </FormAnt.Item>
    </FormAnt>
  );
};

Form.propTypes = {
  form: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
  selectedRow: PropTypes.object.isRequired,
};

export default Form;
