/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Form as FormAnt,
  Input,
} from 'antd';
import PropTypes from 'prop-types';

const Form = ({
  form, onFinish,
}) => (
  <FormAnt
    labelCol={{
      span: 8,
    }}
    wrapperCol={{
      span: 14,
    }}
    layout="horizontal"
    form={form}
    name="TreatmentType"
    onFinish={onFinish}
  >
    <FormAnt.Item
      name={['TreatmentType', 'title']}
      rules={[{ required: true, message: 'Title required!' }]}
      whitespace={false}
      label="Title"
    >
      <Input />
    </FormAnt.Item>
    <FormAnt.Item
      name={['TreatmentType', 'ownLocationPrice']}
      whitespace={false}
      label="Own Location Price"
      rules={[{ required: true, message: 'Please input the price' },
        () => ({
          validator(_, value) {
            if (value != null && value <= 0) {
              return Promise.reject(new Error('Value must be grater then zero'));
            }
            return Promise.resolve();
          },
        }),
      ]}
    >
      <Input suffix="SEK" />
    </FormAnt.Item>

    <FormAnt.Item
      name={['TreatmentType', 'clientLocationPrice']}
      whitespace={false}
      label="Client Location Price"
      rules={[{ required: true, message: 'Please input the price' },
        () => ({
          validator(_, value) {
            if (value != null && value <= 0) {
              return Promise.reject(new Error('Value must be grater then zero'));
            }
            return Promise.resolve();
          },
        }),
      ]}
    >
      <Input suffix="SEK" />
    </FormAnt.Item>
    <FormAnt.Item
      name={['TreatmentType', 'vatRate']}
      rules={[{ required: true, message: 'VatRate required!' }, () => ({
        validator(_, value) {
          if (value != null && value <= 0) {
            return Promise.reject(new Error('Value must be grater then zero'));
          }
          if (value > 100) {
            return Promise.reject(new Error('Value must be less than 100'));
          }
          return Promise.resolve();
        },
      })]}
      whitespace={false}
      label="VAT %"
    >
      <Input type="number" />
    </FormAnt.Item>
  </FormAnt>
);

Form.propTypes = {
  form: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default Form;
