/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Form as FormAnt,
  Select,
} from 'antd';
import PropTypes from 'prop-types';

const { Option } = Select;
const Form = ({
  form, onFinish, selectedRow, isToggleFormModel,
}) => {
  const [fields, setFields] = useState();
  useEffect(() => {
    setFields([
      {
        name: ['order', 'statusId'],
        value: selectedRow?.statusId || null,
      },
    ]);
  }, [isToggleFormModel]);
  return (
    <FormAnt
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 14,
      }}
      layout="horizontal"
      form={form}
      name="Change-Status"
      onFinish={onFinish}
      fields={fields}
    >
      <FormAnt.Item name={['order', 'statusId']} label="Order Status" rules={[{ required: true, message: 'required!' }]}>
        <Select
          showSearch
          placeholder="Order Status"
        >
          <Option value={1}>Pending</Option>
          <Option value={2}>Confirmed</Option>
          <Option value={4}>Cancelled</Option>
        </Select>
      </FormAnt.Item>
    </FormAnt>
  );
};

Form.propTypes = {
  form: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
  selectedRow: PropTypes.object.isRequired,
};

export default Form;
