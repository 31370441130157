/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Form as FormAnt,
  Input,
  Radio,
} from 'antd';
import PropTypes from 'prop-types';

const Form = ({
  form, onFinish,
}) => (
  <FormAnt
    labelCol={{
      span: 8,
    }}
    wrapperCol={{
      span: 14,
    }}
    layout="horizontal"
    form={form}
    name="Title"
    onFinish={onFinish}
  >
    <FormAnt.Item
      name={['Location', 'title']}
      rules={[{ required: true, message: 'Please input the title' }]}
      whitespace={false}
      label="Title"
    >
      <Input />
    </FormAnt.Item>
    <FormAnt.Item name={['Location', 'flag']} label="Location" rules={[{ required: true, message: 'Please select the location' }]}>
      <Radio.Group>
        <Radio value={1}>Client Location </Radio>
        <Radio value={2}>Own Location</Radio>
      </Radio.Group>
    </FormAnt.Item>
  </FormAnt>
);

Form.propTypes = {
  form: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default Form;
