/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import DebounceSelect from '_components/Search';
import { Search as CustomerSearch } from '_api/customer';
import { setCustomer } from '_store/user';
import { setFilter } from '_store/orderGroup';

const Filter = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [fields, setFields] = useState();
  const filters = useSelector(
    (state) => state.orderGroup.filters,
  );
  const customer = useSelector(
    (state) => state.user.customer,
  );

  const setFilterFn = (filter) => dispatch(setFilter(filter));
  const setCustomerFn = (filter) => dispatch(setCustomer(filter));
  useEffect(() => {
    if (customer) {
      setFields([
        {
          name: ['orders', 'customer_id'],
          value: customer?.value || null,
        },
        {
          name: ['customer', 'customerObj'],
          value: customer ? { label: customer?.label, value: customer?.value } : null,
        }]);
      setFilterFn({ ...filters, ...{ customer_id: customer?.value, page: 0 } });
    }
  }, []);
  return (
    <Form
      layout="horizontal"
      form={form}
      fields={fields}
    >
      <Row style={{ marginBottom: '16px' }} gutter={[16, 8]}>
        <Col span={8}>
          <Form.Item name={['orders', 'customer_id']} noStyle />
          <Form.Item name={['customer', 'customerObj']} label="">
            <DebounceSelect
              showSearch
              allowClear
              placeholder="search customer"
              fetchOptions={CustomerSearch}
              onChange={(newValue) => {
                const { value: values = '{"email":"", "customerType":"", "billingAddress":"" , "phoneNumber":""}' } = newValue || {};
                const {
                  id = null,
                  fullName = null,
                } = JSON.parse(values) || {};
                setCustomerFn({ label: fullName, value: id, obj: newValue });
                setFilterFn({ ...filters, ...{ customer_id: id || null, page: 0 } });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
export default Filter;
