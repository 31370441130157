import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';

const Dialog = ({
  visible,
  footer,
  onOk,
  onCancel,
  okText,
  cancelText,
  title,
  children,
  cancelButtonProps,
  confirmLoading,
  afterClose,
}) => (
  <Modal
    visible={visible}
    title={title}
    style={{ top: 20 }}
    okText={okText}
    cancelText={cancelText}
    onOk={onOk}
    onCancel={onCancel}
    footer={footer}
    cancelButtonProps={cancelButtonProps}
    confirmLoading={confirmLoading}
    afterClose={afterClose}
  >
    {children}
  </Modal>
);

Dialog.propTypes = {
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  children: PropTypes.node.isRequired,
  footer: PropTypes.arrayOf(PropTypes.node),
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  cancelButtonProps: PropTypes.object,
  confirmLoading: PropTypes.bool,
  afterClose: PropTypes.func,
};

Dialog.defaultProps = {
  okText: 'submit',
  cancelText: 'Closed',
  footer: undefined,
  cancelButtonProps: { disabled: false },
  confirmLoading: false,
  afterClose: () => {},
};

export default Dialog;
