/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import {
  Form,
} from 'antd';
import Dialog from '_components/Dialog';
import { useCreate } from '_service/location';
import { useStateCompose } from './useStateCompose';
import TabAdjustmentForm from './Form';

const AddForm = () => {
  const [form] = Form.useForm();
  const {
    selectedRow, isToggleFormModel, toggleFormModelFn,
  } = useStateCompose();
  const onError = () => {
    toggleFormModelFn();
    form.resetFields();
  };
  const OnSuccess = () => {
    toggleFormModelFn();
    form.resetFields();
  };
  const { mutate, isLoading } = useCreate(OnSuccess, onError);
  const onOk = () => {
    form.submit();
  };
  const onFinish = (values) => {
    const payload = {
      ...values.Location,
      active: true,
    };
    mutate(payload);
  };
  return (
    <Dialog
      cancelButtonProps={{ disabled: isLoading }}
      title="Add Location"
      confirmLoading={isLoading}
      visible={isToggleFormModel}
      onOk={onOk}
      okText="Save"
      onCancel={toggleFormModelFn}
      afterClose={() => { form.resetFields(); }}
    >
      <TabAdjustmentForm
        form={form}
        onFinish={onFinish}
        selectedRow={selectedRow}
        isLoading={isLoading}
      />
    </Dialog>
  );
};

export default AddForm;
