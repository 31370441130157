/* eslint-disable no-sequences */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  customer: null,
};
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoginSuccess: (state, data) => {
      state.user = data.payload;
    },
    setCustomer: (state, data) => {
      state.customer = data.payload;
    },
  },
});

export const {
  setLoginAttempt,
  setCustomer,
  setLoginSuccess,
  setLoginFailure,
} = userSlice.actions;

export default userSlice.reducer;
