/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import {
  Form,
} from 'antd';
import Dialog from '_components/Dialog';
import { useCreate } from '_service/Customer';
import { dateFormat, FORMAT } from '_utils/date-helper';
import { useStateCompose } from './useStateCompose';
import TabAdjustmentForm from './Form';

const AddForm = () => {
  const [form] = Form.useForm();
  const {
    selectedRow, isToggleFormModel, toggleFormModelFn,
  } = useStateCompose();
  const onError = () => {
    toggleFormModelFn();
    form.resetFields();
  };
  const OnSuccess = () => {
    toggleFormModelFn();
    form.resetFields();
  };
  const { mutate, isLoading } = useCreate(OnSuccess, onError);
  const onOk = () => {
    form.submit();
  };
  const onFinish = (values) => {
    const { Customer } = values || {};
    const payload = {
      ...values.Customer,
      dob: Customer?.dob ? dateFormat(Customer?.dob.$d, FORMAT.YYYYMMDD) : null,
      paymentStartDate: Customer?.paymentStartDate ? dateFormat(Customer?.paymentStartDate.$d, FORMAT.YYYYMMDD) : null,
      paymentEndDate: Customer?.paymentEndDate ? dateFormat(Customer?.paymentEndDate.$d, FORMAT.YYYYMMDD) : null,
      active: true,
    };
    mutate(payload);
  };
  return (
    <Dialog
      cancelButtonProps={{ disabled: isLoading }}
      title="Add Customer"
      confirmLoading={isLoading}
      visible={isToggleFormModel}
      onOk={onOk}
      okText="Save"
      onCancel={toggleFormModelFn}
      afterClose={() => { form.resetFields(); }}
    >
      <TabAdjustmentForm
        form={form}
        onFinish={onFinish}
        selectedRow={selectedRow}
        isLoading={isLoading}
      />
    </Dialog>
  );
};

export default AddForm;
