/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useState } from 'react';
import {
  Table, Row, Button, Tooltip, Space,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { dateFormat, FORMAT } from '_utils/date-helper';
import { useHistory } from 'react-router-dom';
import { isToggleFormModelFn } from '_store/Treatment';
import { useDispatch } from 'react-redux';
import AddForm from 'pages/Treatment/AddForm';
import EditForm from 'pages/Treatment//EditForm';
import Confirmation from 'pages/Treatment//Confirmation';
import { usePartnerInvoice } from './useStateCompose';
import StatusCard from '../../Components/StatusCard';
import './table.css';

const list = [
  { key: 'add-treatment', title: 'Add Treatment' },
];

const DataTable = () => {
  const {
    filters,
    isDataSourceFetching,
    total,
    dataSource,
    setFilterFn,
    Own,
    client,
    isToggleEditFormModel,
    isToggleDeleteModel,
  } = usePartnerInvoice();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState({});
  const history = useHistory();
  const handlePageChange = (page) => {
    const updateFilter = { ...filters, ...{ page: page - 1 } };
    setFilterFn(updateFilter);
  };
  const onActions = (key, select) => {
    const { id } = select;
    switch (key) {
      case 'add-treatment':
        setSelected(select);
        dispatch(isToggleFormModelFn());
        break;
      default:
    }
  };
  const onSave = () => {
    const updateFilter = { ...filters };
    console.log(updateFilter);
    setFilterFn(updateFilter);
  };
  const columns = [
    {
      dataIndex: 'treatmentDatetime',
      title: 'Date',
      width: '14%',
      ellipsis: true,
      render: (startFrom) => (
        <span>
          {dateFormat(startFrom, FORMAT.YYYYMMMDD)}
        </span>
      ),
    },
    {
      dataIndex: 'treatmentDatetime',
      title: 'Time',
      width: '14%',
      ellipsis: true,
      render: (startFrom) => (
        <span>
          {dateFormat(startFrom, FORMAT.HM)}
        </span>
      ),
    },
    {
      dataIndex: 'address',
      title: 'Address',
      width: '20%',
      ellipsis: true,
    },
    // {
    //   dataIndex: 'treatmentStatus',
    //   title: 'Status',
    //   width: '11%',
    //   render: (treatmentStatus, row) => (
    //     <Button type="dashed" onClick={() => {}}>{getStatus(treatmentStatus)}</Button>
    //   ),
    // },
    {
      dataIndex: 'providers',
      title: 'Providers',
      width: '10%',
      render: (providers, row) => (
        <Space size="middle">
          {providers?.name}
        </Space>
      ),
    },
    {
      dataIndex: 'providerPrice',
      title: 'Provider Price',
      width: '10px',
      ellipsis: true,
    },
    {
      dataIndex: 'notes',
      title: 'Notes',
      width: '20px',
      ellipsis: true,
    },
    {
      dataIndex: 'id',
      title: '',
      width: '15px',
      ellipsis: true,
      render: (_, row) => (
        <Space size="middle">
          <a onClick={() => { isToggleEditFormModel({ selectedRow: row }); }}>Edit</a>
          <a onClick={() => { isToggleDeleteModel({ selectedRow: row }); }}>Delete</a>
        </Space>
      ),
    },

  ];
  return (
    <div>
      <div className="card_list">
        <AddForm data={selected} onSave={() => onSave()} />
        <Confirmation />
        <EditForm />
        <Row
          justify="center"
          gutter={[16, 8]}
          style={{
            backgroundColor: '#ffffff', padding: 5, marginBottom: 5, borderRadius: 16,
          }}
        >
          <StatusCard counts={0} type={1} label="Own Location" labelColor="#cf1322" />
          <StatusCard counts={Own?.totalTreatment || 0} label="Total" labelColor="#757575" />
          <StatusCard counts={Own?.used || 0} label="Used " labelColor="#757575" />
          <StatusCard counts={Own?.remaining || 0} label="Unused" labelColor="#757575" />
          <div style={{ display: 'flex', minWidth: 220, justifyContent: 'space-evenly' }}>
            <Tooltip title="Add Treatment">
              <Button
                type="primary"
                shape="round"
                icon={<PlusOutlined />}
                disabled={!(Own?.remaining > 0)}
                onClick={() => {
                  setSelected({ customerId: filters.customer_id, locationType: 2 });
                  dispatch(isToggleFormModelFn());
                }}
              >
                Treatment
              </Button>
            </Tooltip>
            <Tooltip title="Add Order">
              <Button
                type="primary"
                shape="round"
                icon={<PlusOutlined />}
                onClick={() => {
                  const type = 2;
                  history.push(`/order/create/${type}`);
                }}
              >
                Order
              </Button>
            </Tooltip>
          </div>
        </Row>
        <Row
          justify="center"
          gutter={[16, 8]}
          style={{
            backgroundColor: '#ffffff', padding: 5, marginBottom: 5, borderRadius: 16,
          }}
        >
          <StatusCard counts={0} type={1} label="Client Location" labelColor="#cf1322" />
          <StatusCard counts={client?.totalTreatment || 0} label="Total" labelColor="#757575" />
          <StatusCard counts={client?.used || 0} label="Used" labelColor="#757575" />
          <StatusCard counts={client?.remaining || 0} label="Unused" labelColor="#757575" />
          <div style={{ display: 'flex', minWidth: 220, justifyContent: 'space-evenly' }}>
            <Tooltip title="Add Treatment">
              <Button
                type="primary"
                shape="round"
                onClick={() => {
                  console.log(filters, '8858');
                  setSelected({ customerId: filters.customer_id, locationType: 1 });
                  dispatch(isToggleFormModelFn());
                }}
                icon={<PlusOutlined />}
                disabled={!(client?.remaining > 0)}
              >
                Treatment
              </Button>
            </Tooltip>
            <Tooltip title="Add Order">
              <Button
                type="primary"
                shape="round"
                icon={<PlusOutlined />}
                onClick={() => {
                  const type = 1;
                  history.push(`/order/create/${type}`);
                }}
              >
                Order
              </Button>
            </Tooltip>
          </div>
        </Row>
      </div>
      <Table
        loading={isDataSourceFetching}
        pagination={{
          showSizeChanger: false,
          size: 10,
          total,
          current: filters?.page + 1 || 1,
          defaultCurrent: 1,
          position: ['bottomRight'],
          onChange: handlePageChange,
        }}
        size="small"
        scroll={{ x: 1000, y: 500 }}
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={dataSource}
      />
    </div>
  );
};

export default DataTable;
