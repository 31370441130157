import { useGetDob } from '_service/Customer';

export const useTableView = () => {
  const { data, isFetching } = useGetDob();
  const {
    upcomingBirthday = [], todayBirthday = [],

  } = data || {};

  const news = todayBirthday.concat(upcomingBirthday);

  return {
    dataSource: news || [],
    isDataSourceFetching: isFetching,
    total: upcomingBirthday?.results || 0,
  };
};
