/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React from 'react';
import {
  Table, Space, Button,
} from 'antd';
import { getPriceFormate, dateFormat, FORMAT } from '_utils/date-helper';

const DataTable = ({
  filters,
  isDataSourceFetching,
  total,
  dataSource,
  setFilterFn,
  isToggleEditFormModel,
  isToggleDeleteModel,
}) => {
  const handlePageChange = (page) => {
    const updateFilter = { ...filters, ...{ page: page - 1 } };
    setFilterFn(updateFilter);
  };
  const getStatus = (st) => {
    switch (st) {
      case 1:
        return 'Booked';
      case 2:
        return 'Cancelled';
      default:
        return '-';
    }
  };
  const columns = [
    {
      dataIndex: 'treatmentDatetime',
      title: 'Date',
      width: '14%',
      ellipsis: true,
      render: (startFrom) => (
        <span>
          {dateFormat(startFrom, FORMAT.YYYYMMMDD)}
        </span>
      ),
    },
    {
      dataIndex: 'treatmentDatetime',
      title: 'Time',
      width: '14%',
      ellipsis: true,
      render: (startFrom) => (
        <span>
          {dateFormat(startFrom, FORMAT.HM)}
        </span>
      ),
    },
    {
      dataIndex: 'address',
      title: 'Address',
      width: '20%',
      ellipsis: true,
    },
    {
      dataIndex: 'treatmentStatus',
      title: 'Status',
      width: '11%',
      render: (treatmentStatus, row) => (
        <Button type="dashed" onClick={() => {}}>{getStatus(treatmentStatus)}</Button>
      ),
    },
    {
      dataIndex: 'providers',
      title: 'Providers',
      width: '10%',
      render: (providers, row) => (
        <Space size="middle">
          {providers?.name}
        </Space>
      ),
    },
    {
      dataIndex: 'providerPrice',
      title: 'Provider Price',
      width: '10px',
      ellipsis: true,
    },
    {
      dataIndex: 'notes',
      title: 'Notes',
      width: '20px',
      ellipsis: true,
    },
    {
      dataIndex: 'id',
      title: '',
      width: '15px',
      ellipsis: true,
      render: (_, row) => (
        <Space size="middle">
          <a onClick={() => { isToggleEditFormModel({ selectedRow: row }); }}>Edit</a>
          <a onClick={() => { isToggleDeleteModel({ selectedRow: row }); }}>Delete</a>
        </Space>
      ),
    },

  ];
  return (
    <div>
      <Table
        loading={isDataSourceFetching}
        pagination={{
          showSizeChanger: false,
          size: 10,
          total,
          current: filters?.page + 1 || 1,
          defaultCurrent: 1,
          position: ['bottomRight'],
          onChange: handlePageChange,
        }}
        size="small"
        // scroll={{ x: 1000, y: 500 }}
        rowKey={(record) => record.id}
     // rowSelection={rowSelection}
        columns={columns}
        dataSource={dataSource}
      />
    </div>
  );
};

export default DataTable;
