/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/prefer-default-export
export const cleanParams = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === false) {
      delete obj[key];
    } else if (obj[key]) {
      if (key === 'isDisabled') {
        delete obj[key];
        obj.enabled = false;
      }
    }
  });
  Object.keys(obj).forEach((key) => {
    if (
      obj[key] === null
        || obj[key] === ''
        || obj[key] === undefined
        || obj[key].length === 0
        || (key === 'status' && obj[key] === 0)
    ) {
      delete obj[key];
    }
  });
  Object.keys(obj).forEach((key) => {
    if (obj[key] === true) {
      obj[key] = 1;
    }
  });
  return obj;
};

export const PATH = {
  LOGIN_PATH: '/Auth/login',
  ORDER: '/order',
  ORDER_DETAIL: '/detail',
};
