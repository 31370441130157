/* eslint-disable no-unused-vars */
import React from 'react';
import InvoiceTable from './Table';
import Filter from './Filter';
import './index.css';

const InvoiceList = () => (
  <>
    <Filter />
    <InvoiceTable />
  </>

);

export default InvoiceList;
