/* eslint-disable no-return-assign */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import {
  Form as FormAnt,
  Select,
  Input,
  InputNumber,
  Typography,
} from 'antd';
import { Search } from '_api/promo-code';
import { usePaymentTypeDropDown } from '_service/paymentType';
import DebounceSelect from '_components/SearchInput';
import PropTypes from 'prop-types';
import { getDiscountPrice, getPrice, getVat } from 'utilities';
import { instanceDayjs, dateFormat, FORMAT } from '_utils/date-helper';
import { Search as treatmentFetch } from '_api/treatmentType';
import { Search as locationFetch } from '_api/location';

const { Text } = Typography;

const { Option } = Select;
const Form = ({
  form, onFinish, selectedRow, isToggleFormModel, treatmentType, location,
}) => {
  const [fields, setFields] = useState();
  const valuesNd = { current: null };
  const discount = FormAnt.useWatch(['list', 'discountpre'], form);
  const ownLocationPriceW = FormAnt.useWatch(['orderList', 'ownLocationPrice'], form);
  const clientLocationPriceW = FormAnt.useWatch(['orderList', 'clientLocationPrice'], form);
  const durationW = FormAnt.useWatch(['order', 'duration'], form);
  const locationType = FormAnt.useWatch(['orderList', 'locationType'], form);
  const treatmentNumberW = FormAnt.useWatch(['order', 'treatmentNumber'], form);
  const billedAmountPriceW = FormAnt.useWatch(['order', 'billedAmountPrice'], form);
  const customerTypeV = FormAnt.useWatch(['user', 'customerType'], form);
  const Vat = FormAnt.useWatch(['orderList', 'vatRate'], form);

  useEffect(() => {
    setFields([
      {
        name: ['list', 'treatmentTypeId'],
        value: { label: selectedRow?.treatmentType?.title, value: selectedRow.treatmentTypeId } || {},
      },
      {
        name: ['order', 'treatmentTypeId'],
        value: selectedRow.treatmentTypeId || null,
      },
      {
        name: ['user', 'customerType'],
        value: selectedRow.customer.customerType || 0,
      },
      {
        name: ['order', 'locationId'],
        value: selectedRow.locationId || null,
      },
      {
        name: ['orderList', 'ownLocationPrice'],
        value: selectedRow?.treatmentType?.ownLocationPrice,
      },
      {
        name: ['orderList', 'locationType'],
        value: selectedRow?.location?.flag,
      },
      {
        name: ['orderList', 'clientLocationPrice'],
        value: selectedRow?.treatmentType?.clientLocationPrice,
      },
      {
        name: ['orderList', 'vatRate'],
        value: selectedRow?.treatmentType?.vatRate || 0,
      },
      {
        name: ['order', 'treatmentNumber'],
        value: selectedRow.treatmentNumber || '',
      },
      {
        name: ['order', 'actualAmount'],
        value: selectedRow.actualAmount || '',
      },
      {
        name: ['order', 'duration'],
        value: selectedRow.duration || '',
      },
      {
        name: ['list', 'startFrom'],
        value: instanceDayjs(selectedRow.startFrom) || '',
      },
      {
        name: ['list', 'locationId'],
        value: { label: selectedRow?.location?.title, value: selectedRow.locationId } || {},
      },
      {
        name: ['order', 'billedAmountPrice'],
        value: selectedRow.billedAmountPrice || '',
      },
      {
        name: ['list', 'code'],
        value: selectedRow?.promoCode?.code || '',
      },
      {
        name: ['list', 'discountpre'],
        value: selectedRow?.promoCode?.discountpre || '',
      },
      {
        name: ['list', 'promoCode'],
        value: { label: selectedRow?.promoCode?.title, value: selectedRow.promoCodeId } || {},
      },
      {
        name: ['order', 'promoCodeId'],
        value: selectedRow?.promoCodeId || null,
      },
      {
        name: ['select', 'locationAddress'],
        value: selectedRow?.locationAddress || null,
      },
      {
        name: ['select', 'source'],
        value: selectedRow?.source || null,
      },
    ]);
  }, [isToggleFormModel]);

  valuesNd.current = getPrice(locationType === 1 ? clientLocationPriceW : ownLocationPriceW, treatmentNumberW, durationW);
  const vatArray = getVat(billedAmountPriceW, Vat, customerTypeV);
  useEffect(() => {
    if (valuesNd.current) {
      form.setFieldsValue({
        order: { actualAmount: valuesNd.current, billedAmountPrice: discount ? getDiscountPrice(valuesNd.current, discount) : valuesNd.current },
      });
    }
  }, [discount, valuesNd.current]);
  useEffect(() => () => {
    form.resetFields();
    valuesNd.current = null;
  },
  []);
  const onSubmit = (values) => {
    const { startFrom } = values.list;
    const payload = {
      ...values.order,
      startFrom: startFrom && dateFormat(startFrom.$d, FORMAT.YYYYMMMDD),
      id: selectedRow.id,
      vatAmount: vatArray[2] ? vatArray[2] : 0,
      vat: vatArray[1] ? vatArray[1] : 0,
      billedAmountVat: vatArray[0] ? vatArray[0] : billedAmountPriceW,
    };
    onFinish(payload);
  };
  return (
    <FormAnt
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 14,
      }}
      layout="horizontal"
      form={form}
      name="Status"
      onFinish={onSubmit}
      fields={fields}
    >
      <FormAnt.Item noStyle name={['orderList', 'vatRate']} />
      <FormAnt.Item noStyle name={['user', 'customerType']} />
      <FormAnt.Item noStyle name={['order', 'treatmentTypeId']} />
      <FormAnt.Item noStyle name={['orderList', 'clientLocationPrice']} />
      <FormAnt.Item noStyle name={['orderList', 'ownLocationPrice']} />
      <FormAnt.Item label="Treatment Type" name={['list', 'treatmentTypeId']}>
        <DebounceSelect
          showSearch
          allowClear
          placeholder="search Treatment Type"
          fetchOptions={treatmentFetch}
          style={{ width: 200 }}
          onChange={(newValue) => {
            const { value: TreatmentValues = '{}' } = newValue || {};
            const {
              id = null, ownLocationPrice = 0, clientLocationPrice = 0,
            } = JSON.parse(TreatmentValues) || {};
            form.setFieldsValue({
              order: { treatmentTypeId: id },
              orderList: { ownLocationPrice, clientLocationPrice },
            });
          }}
        />
      </FormAnt.Item>
      <FormAnt.Item name={['order', 'treatmentNumber']} label="Number of Treatment">
        <InputNumber />
      </FormAnt.Item>
      <FormAnt.Item name={['order', 'duration']} label="Duration">
        <InputNumber addonBefore="hours" />
      </FormAnt.Item>
      <FormAnt.Item noStyle label="" name={['order', 'locationId']} />
      <FormAnt.Item noStyle label="" name={['orderList', 'locationType']} />
      <FormAnt.Item label="Location" name={['list', 'locationId']}>
        <DebounceSelect
          showSearch
          allowClear
          placeholder="search locations"
          fetchOptions={locationFetch}
          onChange={(newValue) => {
            const { value: values = '{"id":"", "flag":""}' } = newValue || {};
            const {
              id, flag = '',
            } = JSON.parse(values) || {};
            form.setFieldsValue({
              order: { locationId: id },
              orderList: { locationType: flag },
            });
          }}
          style={{ width: 200 }}
        />
      </FormAnt.Item>
      {locationType === 1 ? (
        <FormAnt.Item name={['select', 'locationAddress']} label="Client Address">
          <Input.TextArea />
        </FormAnt.Item>
      ) : ''}
      <FormAnt.Item noStyle label="promoCode" name={['order', 'promoCodeId']} />
      <FormAnt.Item label="Promo" name={['list', 'promoCode']}>
        <DebounceSelect
          showSearch
          allowClear
          placeholder="search Promo"
          fetchOptions={Search}
          style={{ width: 200 }}
          onChange={(newValue) => {
            const { value: values = '{"discountpre":"", "code":""}' } = newValue || {};
            const {
              id, discountpre = '', code,
            } = JSON.parse(values) || {};
            form.setFieldsValue({
              order: {
                promoCodeId: id,
              },
              list: {
                discountpre,
                code,
              },
            });
          }}
        />
      </FormAnt.Item>
      <FormAnt.Item name={['list', 'discountpre']} label="Discount %">
        <Input InputNumber disabled />
      </FormAnt.Item>
      <FormAnt.Item
        label="Promo Code"
        name={['list', 'code']}
      >
        <Input disabled />
      </FormAnt.Item>
      <FormAnt.Item name={['order', 'actualAmount']} label="Calculated Price">
        <Text>
          {valuesNd.current ? `${valuesNd.current} SEK` : ''}
        </Text>
      </FormAnt.Item>
      <FormAnt.Item name={['order', 'billedAmountPrice']} label="Billing Price">
        <InputNumber
          addonBefore={discount && (
            <Text delete>
              {`${valuesNd.current || 0}  `}
            </Text>
          )}
          addonAfter="SEK"
        />
      </FormAnt.Item>
      {customerTypeV ? (
        <FormAnt.Item name={['ordersLists', 'odder']} label="VAT">
          <Text>
            {vatArray[2] ? `${vatArray[2]} SEK` : '0 SEK'}
          </Text>
        </FormAnt.Item>
      ) : null}
      <FormAnt.Item name={['orderList', 'odder']} label="Billing Price + VAT">
        <Text>
          {vatArray[0] ? `${vatArray[0]} SEK` : `${billedAmountPriceW || 0} SEK`}
        </Text>
      </FormAnt.Item>
    </FormAnt>
  );
};

Form.propTypes = {
  form: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
  selectedRow: PropTypes.object.isRequired,
};

export default Form;
