import { useQuery } from 'react-query';
import { useCallback } from 'react';
import { QUERY_KEY } from '_service/Alias';
import { paymentType } from '_api/PaymentType';

export const usePaymentTypeDropDown = (params) => useQuery([QUERY_KEY.PAYMENT_TYPE_DROPDOWN,
  params],
paymentType, {
  select: useCallback(
    ({ data = {} }) => data.map((ele) => ({
      label: `${ele.title}`,
      value: ele.id,
    })),
    [],
  ),
});
