import { isToggleDeleteModelFn } from '_store/Location';
import { useSelector, useDispatch } from 'react-redux';

export const useGetState = () => {
  const dispatch = useDispatch();
  const isConfirmationModel = useSelector(
    (state) => state.Location.isToggleDeleteModel,
  );
  const confirmation = useSelector(
    (state) => state.Location.confirmationContent,
  );
  const selectedRow = useSelector(
    (state) => state.Location.selectedRow,
  );
  const toggleConfirmation = () => dispatch(isToggleDeleteModelFn());
  return [isConfirmationModel, toggleConfirmation, confirmation, selectedRow];
};
