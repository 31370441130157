/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import {
  Form,
} from 'antd';
import Dialog from '_components/Dialog';
import { useUpdate } from '_service/Order';
import { useTreatmentTypeDropDown } from '_service/treatmentType';
import { useLocationTypeDropDown } from '_service/location';
import { dateFormat, FORMAT } from '_utils/date-helper';
import { useStateCompose } from './useStateCompose';
import TForm from './Form';

const AddForm = () => {
  const [form] = Form.useForm();
  const {
    selectedRow, isToggleFormModel, toggleFormModelFn,
  } = useStateCompose();
  const { data: treatmentType = [] } = useTreatmentTypeDropDown({});
  const { data: location = [] } = useLocationTypeDropDown({});
  const onError = () => {
    toggleFormModelFn();
    form.resetFields();
  };
  const OnSuccess = () => {
    toggleFormModelFn();
    form.resetFields();
  };
  const { mutate, isLoading } = useUpdate(OnSuccess, onError);
  const onOk = () => {
    form.submit();
  };

  const onFinish = (values) => {
    const { treatmentTypeId = {}, locationId = {}, startFrom = null } = values?.list || {};
    const payload = {
      ...values.order,
      id: selectedRow.id,
      treatmentTypeId: treatmentTypeId?.value || null,
      locationId: locationId?.value || null,
      startFrom: dateFormat(startFrom.$d, FORMAT.YYYYMMDD),
    };
    mutate(payload);
  };
  return (
    <>
      {isToggleFormModel && (
      <Dialog
        cancelButtonProps={{ disabled: isLoading }}
        title="Order Detail"
        confirmLoading={isLoading}
        visible={isToggleFormModel}
        onOk={onOk}
        okText="Save"
        onCancel={toggleFormModelFn}
        afterClose={() => { form.resetFields(); }}
      >
        <TForm
          form={form}
          onFinish={onFinish}
          isToggleFormModel={isToggleFormModel}
          selectedRow={selectedRow}
          isLoading={isLoading}
          treatmentType={treatmentType}
          location={location}
        />

      </Dialog>
      )}
    </>
  );
};

export default AddForm;
