/* eslint-disable no-return-await */
/* eslint-disable no-unused-vars */
import { apiCaller } from '../middleware/api-caller';
import { ENDPOINTS, REQUEST_TYPES } from '../middleware/url';

// eslint-disable-next-line no-return-await
export const treatmentType = async ({ queryKey }) => {
  try {
    const [_key, params] = queryKey;
    const result = await apiCaller({
      method: REQUEST_TYPES.GET,
      url: `${ENDPOINTS.TREATMENT}/search/`,
      Auth: true,
      params: { ...params },
    });
    const { response = [] } = result || {};
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const Search = async (data) => {
  try {
    const result = await apiCaller({
      method: REQUEST_TYPES.GET,
      url: `${ENDPOINTS.TREATMENT}/search`,
      Auth: true,
      params:
        {
          title: data,
          size: 10,
        },
    });
    const { response } = result || {};
    if (!response) {
      return [];
    }
    return response.data.map((ele) => ({
      label: `${ele.title}`,
      value: ele.id,
      key: ele.id,
    }));
  } catch (error) {
    return [];
  }
};
export const getByOrderId = async ({ queryKey }) => {
  try {
    const [_key, params] = queryKey;
    const result = await apiCaller({
      method: REQUEST_TYPES.GET,
      url: `${ENDPOINTS.TREATMENT}`,
      Auth: true,
      params: { ...params },
    });
    const { response = [] } = result || {};
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const get = async ({ queryKey }) => {
  try {
    const [_key, params] = queryKey;
    const result = await apiCaller({
      method: REQUEST_TYPES.GET,
      url: `${ENDPOINTS.TREATMENT}/search/`,
      Auth: true,
      params: { ...params },
    });
    return result || {};
  } catch (error) {
    throw new Error(error);
  }
};

export const create = async (data) => await apiCaller(
  {
    method: REQUEST_TYPES.POST,
    url: ENDPOINTS.TREATMENT,
    Auth: true,
    data: { ...data },
  },
);

export const update = async (data) => {
  const { id } = data;
  const params = data;
  if (data?.id) { delete params.id; }
  return await apiCaller(
    {
      method: REQUEST_TYPES.PATCH,
      url: `${ENDPOINTS.TREATMENT}/${id}/`,
      Auth: true,
      data: { ...params },
    },
  );
};

export const deleteRecord = async (data) => {
  const { id } = data;
  return await apiCaller(
    {
      method: REQUEST_TYPES.PATCH,
      url: `${ENDPOINTS.TREATMENT}/${id}/`,
      Auth: true,
      data: { active: false },
    },
  );
};

export const getInvoice = async (params) => {
  try {
    const result = await apiCaller({
      method: REQUEST_TYPES.GET,
      url: `${ENDPOINTS.TREATMENT_INVOICE}/`,
      Auth: true,
      params: { ...params },
    });
    const { response = [] } = result || {};
    return response;
  } catch (error) {
    return null;
  }
};

export const getTreatmentByUser = async ({ queryKey }) => {
  try {
    const [_key, params] = queryKey;
    const data = { ...params };
    const response = await apiCaller({
      method: REQUEST_TYPES.GET,
      url: `${ENDPOINTS.TREATMENT_CUSTOMER}`,
      Auth: true,
      params: {
        ...data,
      },
    });
    if (!response) {
      throw new Error('Network response was not ok');
    }
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
