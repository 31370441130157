import { isToggleDeleteModelFn } from '_store/Provider';
import { useSelector, useDispatch } from 'react-redux';

export const useGetState = () => {
  const dispatch = useDispatch();
  const isConfirmationModel = useSelector(
    (state) => state.Provider.isToggleDeleteModel,
  );
  const confirmation = useSelector(
    (state) => state.Provider.confirmationContent,
  );
  const selectedRow = useSelector(
    (state) => state.Provider.selectedRow,
  );
  const toggleConfirmation = () => dispatch(isToggleDeleteModelFn());
  return [isConfirmationModel, toggleConfirmation, confirmation, selectedRow];
};
