/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  PageHeader, Button, Descriptions, Row, Col,
} from 'antd';
import BarChar from '../BarChar';
import LinChar from '../Line';
import ListView from '../ListView';
import ListViewPay from '../ListViewPay';

const MainBoard = () => (
  <div>
    <Row gutter={[16, 16]} justify="center">
      <Col span={12}>
        <PageHeader
          ghost={false}
          title="Orders Numbers"
        >
          <BarChar />
        </PageHeader>
      </Col>
      <Col span={12}>
        <PageHeader
          ghost={false}
          title="Up coming birthdays"
        >
          <ListView />
        </PageHeader>
      </Col>
      <Col span={12}>
        <PageHeader
          ghost={false}
          title="Revenue"
        >
          <LinChar />
        </PageHeader>
      </Col>
      <Col span={12}>
        <PageHeader
          ghost={false}
          title="Customer Payment Expiry"
        >
          <ListViewPay />
        </PageHeader>
      </Col>
    </Row>
  </div>
);

export default MainBoard;
