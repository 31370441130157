/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useGetOrder } from '_service/orderGroup';
import {
  isToggleEditFormModelFn, isToggleDeleteModelFn,
} from '_store/Treatment';

import {
  setFilter,
  isToggleStatusModel,
  reset,
} from '_store/orderGroup';

export const usePartnerInvoice = () => {
  const dispatch = useDispatch();
  const { data, isFetching } = useGetOrder();
  useEffect(() => () => { dispatch(reset()); }, []);
  const filters = useSelector(
    (state) => state.orderGroup.filters,
  );
  const selectedRows = useSelector(
    (state) => state.orderGroup.selectedRows,
  );
  const selectedAll = useSelector(
    (state) => state.orderGroup.selectedAll,
  );
  const setFilterFn = (filter) => dispatch(setFilter(filter));

  const isToggleStatusModelFn = (values) => dispatch(isToggleStatusModel(values));
  const isToggleFormModel = useSelector(
    (state) => state.Treatment.isToggleEditFormModel,
  );
  const selectedRow = useSelector(
    (state) => state.Treatment.selectedRow,
  );
  const isToggleEditFormModel = (payload) => dispatch(isToggleEditFormModelFn(payload));
  const isToggleDeleteModel = (payload) => dispatch(isToggleDeleteModelFn(payload));
  return {
    selectedRows,
    filters,
    dataSource: data?.response?.data || [],
    isDataSourceFetching: isFetching,
    total: data?.response?.results || 0,
    selectedAll,
    setFilterFn,
    client: data?.totalDetails.find((x) => x.locationType === 1) || [],
    Own: data?.totalDetails.find((x) => x.locationType === 2) || [],
    totalCustomer: data?.totalCustomer || 0,
    isToggleStatusModelFn,
    isToggleEditFormModel,
    isToggleDeleteModel,
  };
};
