/* eslint-disable import/no-unresolved */
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useGetOrderChartJs } from '_service/Order';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
    },
  },
};

const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

export default function App() {
  const { data } = useGetOrderChartJs();
  const currentTime = new Date();
  const {
    labelCurrent = currentTime.getFullYear().toString(),
    labelPrev = (currentTime.getFullYear() - 1).toString(),
    CurrentLine = [0, 0, 0, 0, 0, 0, 0, 0, 0],
    PrevLine = [0, 0, 0, 0, 0, 0, 0, 0, 0],
  } = data || {};
  const datas = {
    labels,
    datasets: [
      {
        label: labelPrev,
        data: PrevLine,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
      {
        label: labelCurrent,
        data: CurrentLine.slice(0, currentTime.getMonth() + 1),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };
  return (

    <div style={{ height: 300 }}>
      <Line options={options} data={datas} />
    </div>
  );
}
