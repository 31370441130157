/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  BrowserRouter, Route, Switch, Redirect,
} from 'react-router-dom'; //
import { PATH } from 'utilities/common';
import { useSelector } from 'react-redux';
import { DashboardLayout, AuthLayout } from './_layouts';
import {
  Login, Order, AddOrder, PromoCode, Customer, TreatmentType, Location, Provider, Treatment, Dashboard, Extract, OrderGroup,
} from './pages';

const AppRouterComponent = BrowserRouter;

const AppRouter = () => {
  const { user } = useSelector(
    (state) => state.user,
  );
  return (
    <AppRouterComponent>
      <Switch>
        <RouteWithLayout exact path={PATH.LOGIN_PATH} privateRoute={false} user={user} component={Login} layout={AuthLayout} />
        <RouteWithLayout exist path="/dashboard" privateRoute user={user} component={Dashboard} layout={DashboardLayout} />
        <RouteWithLayout exist path="/orders" privateRoute user={user} component={Order} layout={DashboardLayout} />
        <RouteWithLayout exist path="/treatment" privateRoute user={user} component={Treatment} layout={DashboardLayout} />
        <RouteWithLayout path="/order/create/:type" privateRoute user={user} component={AddOrder} layout={DashboardLayout} />
        <RouteWithLayout path="/promo-code" privateRoute user={user} component={PromoCode} layout={DashboardLayout} />
        <RouteWithLayout path="/treatment-type" privateRoute user={user} component={TreatmentType} layout={DashboardLayout} />
        <RouteWithLayout path="/customer" privateRoute user={user} component={Customer} layout={DashboardLayout} />
        <RouteWithLayout path="/location" privateRoute user={user} component={Location} layout={DashboardLayout} />
        <RouteWithLayout path="/location" privateRoute user={user} component={Location} layout={DashboardLayout} />
        <RouteWithLayout path="/provider" privateRoute user={user} component={Provider} layout={DashboardLayout} />
        <RouteWithLayout path="/extract" privateRoute user={user} component={Extract} layout={DashboardLayout} />
        <RouteWithLayout exist path="/orders-groups" privateRoute user={user} component={OrderGroup} layout={DashboardLayout} />
        {!user && (<Redirect to="/dashboard" />)}
        {!!user && (<Redirect to={PATH.LOGIN_PATH} />)}
      </Switch>
    </AppRouterComponent>
  );
};
const RouteWithLayout = ({
  component: Component, user = null, isMatch, privateRoute, layout: Layout, ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (user && privateRoute) {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }
      if (user) {
        return (
          <Redirect to="/dashboard" />
        );
      }
      if (!privateRoute) {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }
      if (!user && privateRoute) {
        return (
          <Redirect to={PATH.LOGIN_PATH} />
        );
      }

      return (
        <Redirect to={PATH.LOGIN_PATH} />
      );
    }}
  />
);

export default AppRouter;
