/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Layout,
  Row,
  Col,
  Button,
  Typography,
  Select,
  Space,
} from 'antd';
import { DatePicker } from '_components/DateControls';
import ReportGenerate from './ReportGenerate';

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const Reports = (props) => (
  <>
    <Layout>
      <Content>
        <ReportGenerate />
      </Content>
    </Layout>
  </>
);

Reports.propTypes = {
  // bla: PropTypes.string,
};

Reports.defaultProps = {
  // bla: 'test',
};

export default Reports;
