export const QUERYKEY = {
  INVOICE_TYPE: 'Invoice_Type',
  INVOICE_STATUS: 'Invoice_Status',
  PARTNER_INVOICE: 'invoice',
  GET_REPORT_TYPE: 'Report_Type',
  PARTNER_INVOICE_CYCLE: 'Partner_Invoice_Cycle',
  PARTNER_INVOICE_CYCLE_DEFAULT: 'PARTNER_INVOICE_CYCLE_REMOVE',
  PARTNER_INVOICE_CYCLE_REMOVE: 'PARTNER_INVOICE_CYCLE_REMOVE',
};

export const QUERY_KEY = {
  PAYMENT_TYPE_DROPDOWN: 'PAYMENT_TYPE_DROPDOWN',
  TREATMENT_TYPE_DROPDOWN: 'TREATMENT_TYPE_DROPDOWN',
  LOCATION_DROPDOWN: 'LOCATION_DROPDOWN',
  PROMO_CODE_DROPDOWN: 'PROMO_CODE_DROPDOWN',
  INVOICE_STATUS: 'Invoice_Status',
  PARTNER_INVOICE: 'invoice',
  GET_REPORT_TYPE: 'Report_Type',
  PARTNER_INVOICE_CYCLE: 'Partner_Invoice_Cycle',
  PARTNER_INVOICE_CYCLE_DEFAULT: 'PARTNER_INVOICE_CYCLE_REMOVE',
  PARTNER_INVOICE_CYCLE_REMOVE: 'PARTNER_INVOICE_CYCLE_REMOVE',
  GET_ORDER: 'GET_ORDER',
  GET_ORDER_ID: 'GET_ORDER_ID',
  GET_PROMO_CODE: 'GET_CODE',
  GET_CUSTOMER: 'GET_CUSTOMER',
  GET_DELETE: 'GET_CUSTOMER',
  TREATMENT_TYPE: 'TREATMENT_TYPE',
  LOCATION: 'LOCATION',
  TREATMENT: 'TREATMENT',
  PROVIDER: 'PROVIDER',
  GET_CUSTOMER_DOB: 'GET_CUSTOMER_DOB',
  GET_ORDER_ID_JS: 'GET_ORDER_ID_JS',
  GET_ORDER_ID_USER: 'GET_ORDER_ID_USER',
  GET_ORDER_GROUP: 'GET_ORDER_GROUP',
};
