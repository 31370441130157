/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Descriptions, Button } from 'antd';
import { dateFormat, FORMAT } from '_utils/date-helper';
import { getStatus } from 'utilities';
import {
  isToggleOrderDetailModel,
  isTogglePaymentDetailModel,
  isToggleCustomerEditModel,
  isTogglePriceDetailModel,
} from '_store/order';
import { useDispatch } from 'react-redux';

const AddOrder = (
  { data },
) => {
  const {
    orderNumber = '',
    createdAt = null,
    treatmentNumber = 0,
    startFrom = null,
    location = {},
    billedAmountPrice,
    treatmentType = {},
    duration = '',
    promoCode = {},
    customer = {},
    paymentType = {},
    paymentRef = {},
    statusId = null,
    bookedCount = 0,
    cancelledCount = 0,
    vat = '0 %',
    locationAddress = '',
    billedAmountVat = '0 SEK',
    vatAmount = '0 SEK',
    source = '',
  } = data;
  const dispatch = useDispatch();
  return (
    <>
      <Descriptions
        title={<h4 style={{ margin: '10px' }}>Customer</h4>}
        bordered
        extra={(
          <Button
            type="primary"
            onClick={() => {
              bookedCount === 0 ? dispatch(isToggleCustomerEditModel({ selectedRows: data }))
                : null;
            }}
          >
            Edit
          </Button>
)}
        style={{
          backgroundColor: '#ffffff',
          marginTop: 20,
        }}
        labelStyle={{ fontWeight: 600, backgroundColor: '#ffffff' }}
        contentStyle={{ backgroundColor: '#ffffff !important' }}
        column={{
          xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1,
        }}
      >
        <Descriptions.Item label="Name">{customer?.fullName || '-'}</Descriptions.Item>
        <Descriptions.Item label="Email">{customer?.email || '-'}</Descriptions.Item>
        <Descriptions.Item label="Phone No">{customer?.phoneNumber || '-'}</Descriptions.Item>
        <Descriptions.Item label="Billing Address">{customer?.billingAddress || '-'}</Descriptions.Item>
      </Descriptions>
      <Descriptions
        bordered
        title={<h4 style={{ margin: '10px' }}>Order Schedule & Payment Type</h4>}
        extra={<Button type="primary" onClick={() => { dispatch(isTogglePaymentDetailModel({ selectedRows: data })); }}>Edit</Button>}
        style={{
          backgroundColor: '#ffffff',
          marginTop: 20,
        }}
        labelStyle={{ fontWeight: 600, backgroundColor: '#ffffff' }}
        contentStyle={{ backgroundColor: '#ffffff !important' }}
        column={{
          xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1,
        }}
      >
        <Descriptions.Item label="Creation Date">
          {dateFormat(createdAt, FORMAT.YYYYMMMDD)}
        </Descriptions.Item>
        <Descriptions.Item label="Source">{source}</Descriptions.Item>
        <Descriptions.Item label="Number">{orderNumber}</Descriptions.Item>
        <Descriptions.Item label="Payment Type">{getStatus(statusId)}</Descriptions.Item>
        <Descriptions.Item label="Payment Reference">{paymentRef || ''}</Descriptions.Item>
      </Descriptions>
      <Descriptions
        title={<h4 style={{ margin: '10px' }}>Order Details</h4>}
        bordered
        extra={(
          <Button
            type="primary"
            disabled={!(bookedCount === 0)}
            onClick={() => {
              dispatch(isTogglePriceDetailModel({ selectedRows: data }));
            }}
          >
            Edit
          </Button>
              )}
        style={{
          backgroundColor: '#ffffff',
          marginTop: 20,
        }}
        labelStyle={{ fontWeight: 600, backgroundColor: '#ffffff' }}
        contentStyle={{ backgroundColor: '#ffffff !important' }}
        column={{
          xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1,
        }}
      >
        <Descriptions.Item label="Type">{treatmentType?.title || '-'}</Descriptions.Item>
        <Descriptions.Item label="Date">{dateFormat(startFrom, FORMAT.YYYYMMMDD)}</Descriptions.Item>
        <Descriptions.Item label="Treatments Booked">{bookedCount || '-'}</Descriptions.Item>
        <Descriptions.Item label="Treatments Balance">{(treatmentNumber - bookedCount) || '0'}</Descriptions.Item>
        <Descriptions.Item label="Duration (hours)">{duration}</Descriptions.Item>
        <Descriptions.Item label="Location">{location?.title || '-'}</Descriptions.Item>
        {(location?.flag === 1) && (<Descriptions.Item label="Client Address">{locationAddress || '-'}</Descriptions.Item>)}
        <Descriptions.Item label="Promo code">{promoCode?.title || '-'}</Descriptions.Item>
        <Descriptions.Item label="VAT">{`${vat} %` || '0 %'}</Descriptions.Item>
        <Descriptions.Item label="Price + VAT">{billedAmountVat || ''}</Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default AddOrder;
