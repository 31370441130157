/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Card, Col } from 'antd';

const { Meta } = Card;

const StatusCard = ({
  counts, label, labelColor, type = 0,
}) => (
  <Col span={4}>
    <Card bordered={false} bodyStyle={{ padding: '0px', backgroundColor: '#f7f7f7', borderRadius: '16px' }} style={{ textAlign: 'center' }}>

      <p style={{
        textAlign: 'center', fontSize: '18px', fontWeight: 700, margin: '5px',
      }}
      >
        <span style={{
          margin: '5px', textAlign: 'center', fontSize: '16px', fontWeight: 600, color: labelColor,
        }}
        >
          {' '}
          {label}

        </span>
        { type !== 1 ? (
          <span style={{
            textAlign: 'center', fontSize: '18px', fontWeight: 700,
          }}
          >
            {counts}
          </span>
        ) : ''}
      </p>

    </Card>
  </Col>

);

export default StatusCard;
